import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Checkbox from '../../Components/Widgets/Checkbox'
import SettingsPageStore from './SettingsPageStore'
import { canViewFlexiRemote } from '../../State/Permissions/HasPermissions'
import { Selector } from '../../Components/Selector'

export default observer(() => {
    const [settingsStore, setStore] = useState(new SettingsPageStore())
    return (
        <div className="max-w-[60em] py-[2em] px-[4px]">
            <div className="flex items-center py-[2em]">
                <span className="inline-block">Sort Phases By:</span>
                <div className="mx-[1em] w-[12em]">
                    <Selector
                        style={{ width: '100%' }}
                        value={settingsStore.settings.sortPhasesBy}
                        onChange={function (value) {
                            settingsStore.changeSetting('sortPhasesBy', value)
                        }}
                        options={[
                            {
                                label: 'Start Date',
                                value: 'startDate',
                            },
                            {
                                label: 'Title',
                                value: 'tile',
                            },
                        ]}
                        variant="secondary"
                    />
                </div>
            </div>
            <div>
                <Checkbox
                    value={settingsStore.settings.allowNoPhase}
                    onChange={(value) => {
                        settingsStore.changeSetting('allowNoPhase', value)
                    }}
                    label="Allow time to be logged without a selecting a phase."
                    variant="secondary"
                />
            </div>
            <div className="mt-[2em]">
                <Checkbox
                    value={settingsStore.settings.useTasks}
                    onChange={function (value) {
                        settingsStore.changeSetting('useTasks', value)
                    }}
                    label="Allow time to be logged against tasks."
                    variant="secondary"
                />
            </div>
            <div className="mt-[2em]">
                <Checkbox
                    value={settingsStore.settings.allowAfterPhaseEnd}
                    onChange={(value) => {
                        settingsStore.changeSetting('allowAfterPhaseEnd', value)
                    }}
                    label="Allow time to be logged against a phase that has ended."
                    variant="secondary"
                />
            </div>
            <div className="mt-[3em]">
                <p>
                    Allow phases with the following statuses to be visible when
                    entering time:
                </p>
            </div>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryStatus.includes(
                        'active'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryStatus', [
                                'active',
                                ...settingsStore.settings.timeEntryStatus,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryStatus',
                                settingsStore.settings.timeEntryStatus.filter(
                                    (s) => s !== 'active'
                                )
                            )
                        }
                    }}
                    label="Active"
                    variant="secondary"
                />
            </div>
            <div className="ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryStatus.includes(
                        'prospective'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryStatus', [
                                'prospective',
                                ...settingsStore.settings.timeEntryStatus,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryStatus',
                                settingsStore.settings.timeEntryStatus.filter(
                                    (s) => s !== 'prospective'
                                )
                            )
                        }
                    }}
                    label="Prospective"
                    variant="secondary"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <Checkbox
                    value={settingsStore.settings.timeEntryStatus.includes(
                        'onHold'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryStatus', [
                                'onHold',
                                ...settingsStore.settings.timeEntryStatus,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryStatus',
                                settingsStore.settings.timeEntryStatus.filter(
                                    (s) => s !== 'onHold'
                                )
                            )
                        }
                    }}
                    label="On Hold"
                    variant="secondary"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <Checkbox
                    value={settingsStore.settings.timeEntryStatus.includes(
                        'archived'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryStatus', [
                                'archived',
                                ...settingsStore.settings.timeEntryStatus,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryStatus',
                                settingsStore.settings.timeEntryStatus.filter(
                                    (s) => s !== 'archived'
                                )
                            )
                        }
                    }}
                    label="Archived"
                    variant="secondary"
                />
            </div>
            <p className="mt-[3em]">
                Allow staff to flag time entries with the following:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryFlags.includes(
                        'variation'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryFlags', [
                                'variation',
                                ...settingsStore.settings.timeEntryFlags,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryFlags',
                                settingsStore.settings.timeEntryFlags.filter(
                                    (s) => s !== 'variation'
                                )
                            )
                        }
                    }}
                    label="Variation"
                    variant="secondary"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <Checkbox
                    value={settingsStore.settings.timeEntryFlags.includes(
                        'billable'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryFlags', [
                                'billable',
                                ...settingsStore.settings.timeEntryFlags,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryFlags',
                                settingsStore.settings.timeEntryFlags.filter(
                                    (s) => s !== 'billable'
                                )
                            )
                        }
                    }}
                    label="Billable"
                    variant="secondary"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <Checkbox
                    value={settingsStore.settings.timeEntryFlags.includes(
                        'overtime'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('timeEntryFlags', [
                                'overtime',
                                ...settingsStore.settings.timeEntryFlags,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryFlags',
                                settingsStore.settings.timeEntryFlags.filter(
                                    (s) => s !== 'overtime'
                                )
                            )
                        }
                    }}
                    label="Overtime"
                    variant="secondary"
                />
            </div>
            {canViewFlexiRemote() ? (
                <div style={{ marginLeft: '2em' }}>
                    <Checkbox
                        value={settingsStore.settings.timeEntryFlags.includes(
                            'remote'
                        )}
                        onChange={function (value) {
                            if (value) {
                                settingsStore.changeSetting('timeEntryFlags', [
                                    'remote',
                                    ...settingsStore.settings.timeEntryFlags,
                                ])
                            } else {
                                settingsStore.changeSetting(
                                    'timeEntryFlags',
                                    settingsStore.settings.timeEntryFlags.filter(
                                        (s) => s !== 'remote'
                                    )
                                )
                            }
                        }}
                        label="Remote"
                        variant="secondary"
                    />
                </div>
            ) : null}
            {canViewFlexiRemote() ? (
                <div style={{ marginLeft: '2em' }}>
                    <Checkbox
                        value={settingsStore.settings.timeEntryFlags.includes(
                            'flexi'
                        )}
                        onChange={function (value) {
                            if (value) {
                                settingsStore.changeSetting('timeEntryFlags', [
                                    'flexi',
                                    ...settingsStore.settings.timeEntryFlags,
                                ])
                            } else {
                                settingsStore.changeSetting(
                                    'timeEntryFlags',
                                    settingsStore.settings.timeEntryFlags.filter(
                                        (s) => s !== 'flexi'
                                    )
                                )
                            }
                        }}
                        label="Flexi"
                        variant="secondary"
                    />
                </div>
            ) : null}
            <p className="mt-[3em]">
                Allow staff to enter time against phases:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryAllocations.includes(
                        'noAllocations'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                [
                                    'noAllocations',
                                    ...settingsStore.settings
                                        .timeEntryAllocations,
                                ]
                            )
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                settingsStore.settings.timeEntryAllocations.filter(
                                    (s) => s !== 'noAllocations'
                                )
                            )
                        }
                    }}
                    label="Without budgets or allocations for that staff member."
                    variant="secondary"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryAllocations.includes(
                        'budgets'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                [
                                    'budgets',
                                    ...settingsStore.settings
                                        .timeEntryAllocations,
                                ]
                            )
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                settingsStore.settings.timeEntryAllocations.filter(
                                    (s) => s !== 'budgets'
                                )
                            )
                        }
                    }}
                    label="With budgets for that staff member."
                    variant="secondary"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.timeEntryAllocations.includes(
                        'allocations'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                [
                                    'allocations',
                                    ...settingsStore.settings
                                        .timeEntryAllocations,
                                ]
                            )
                        } else {
                            settingsStore.changeSetting(
                                'timeEntryAllocations',
                                settingsStore.settings.timeEntryAllocations.filter(
                                    (s) => s !== 'allocations'
                                )
                            )
                        }
                    }}
                    label="With monthly allocations for that staff member."
                    variant="secondary"
                />
            </div>
            <p className="mt-[3em]">Auto-populate timesheets for phases:</p>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.autoPopulate.includes(
                        'budgets'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('autoPopulate', [
                                'budgets',
                                ...settingsStore.settings.autoPopulate,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'autoPopulate',
                                settingsStore.settings.autoPopulate.filter(
                                    (s) => s !== 'budgets'
                                )
                            )
                        }
                    }}
                    label="With time budgeted for that staff member."
                    variant="secondary"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <Checkbox
                    value={settingsStore.settings.autoPopulate.includes(
                        'allocations'
                    )}
                    onChange={function (value) {
                        if (value) {
                            settingsStore.changeSetting('autoPopulate', [
                                'allocations',
                                ...settingsStore.settings.autoPopulate,
                            ])
                        } else {
                            settingsStore.changeSetting(
                                'autoPopulate',
                                settingsStore.settings.autoPopulate.filter(
                                    (s) => s !== 'allocations'
                                )
                            )
                        }
                    }}
                    label="With monthly allocations assigned to that staff member."
                    variant="secondary"
                />
            </div>
            <div className="mt-[2em]">
                <Checkbox
                    value={settingsStore.settings.updateHoursFromRevenue}
                    onChange={function (value) {
                        settingsStore.changeSetting(
                            'updateHoursFromRevenue',
                            value
                        )
                    }}
                    label="Automatically adjust Staff Hours when editing Revenue in Forecasts"
                    variant="secondary"
                />
                <Checkbox
                    value={settingsStore.settings.updateRevenueFromHours}
                    onChange={function (value) {
                        settingsStore.changeSetting(
                            'updateRevenueFromHours',
                            value
                        )
                    }}
                    label="Automatically adjust Revenue when editing Staff Hours in Forecasts"
                    variant="secondary"
                />
            </div>
            <div className="mt-[4em]">
                <div className="flex items-center mb-[0.5em]">
                    <Selector
                        style={{
                            width: '8em',
                            marginRight: '1em',
                        }}
                        value={settingsStore.settings.autoUpdateRevenue.action}
                        onChange={function (value) {
                            settingsStore.changeSetting('autoUpdateRevenue', {
                                ...settingsStore.settings.autoUpdateRevenue,
                                action: value,
                            })
                        }}
                        options={[
                            {
                                label: 'Ask Me to',
                                value: 'ask',
                            },
                            {
                                label: 'Always',
                                value: 'automatic',
                            },
                            {
                                label: 'Never',
                                value: 'never',
                            },
                        ]}
                        variant="secondary"
                    />
                    <span>
                        Automatically adjust the Revenue Forecast when saving
                        Projects and Invoices.
                    </span>
                </div>
                <Checkbox
                    value={
                        settingsStore.settings.autoUpdateRevenue.adjustOnLoad
                    }
                    onChange={function (value) {
                        settingsStore.changeSetting('autoUpdateRevenue', {
                            ...settingsStore.settings.autoUpdateRevenue,
                            adjustOnLoad: value,
                        })
                    }}
                    label="Automatically adjust Revenue when loading the Revenue Forecast"
                    variant="secondary"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={
                                settingsStore.settings.autoUpdateRevenue.budget
                            }
                            onChange={function (value) {
                                settingsStore.changeSetting(
                                    'autoUpdateRevenue',
                                    {
                                        ...settingsStore.settings
                                            .autoUpdateRevenue,
                                        budget: value,
                                    }
                                )
                            }}
                            options={[
                                {
                                    label: 'Total Budget',
                                    value: 'total',
                                },
                                {
                                    label: 'Remaining Budget',
                                    value: 'remaining',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={
                                settingsStore.settings.autoUpdateRevenue.start
                            }
                            onChange={function (value) {
                                settingsStore.changeSetting(
                                    'autoUpdateRevenue',
                                    {
                                        ...settingsStore.settings
                                            .autoUpdateRevenue,
                                        start: value,
                                    }
                                )
                            }}
                            options={[
                                {
                                    label: 'Start Date',
                                    value: 'startDate',
                                },
                                {
                                    label: 'Current Date',
                                    value: 'now',
                                },
                                {
                                    label: 'End Date',
                                    value: 'endDate',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={settingsStore.settings.autoUpdateRevenue.end}
                            onChange={function (value) {
                                settingsStore.changeSetting(
                                    'autoUpdateRevenue',
                                    {
                                        ...settingsStore.settings
                                            .autoUpdateRevenue,
                                        end: value,
                                    }
                                )
                            }}
                            options={[
                                {
                                    label: 'Start Date',
                                    value: 'startDate',
                                },
                                {
                                    label: 'End Date',
                                    value: 'endDate',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-[3em]">
                <divs
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5em',
                    }}
                >
                    <Selector
                        style={{
                            width: '8em',
                            marginRight: '1em',
                        }}
                        value={settingsStore.settings.autoUpdateHours.action}
                        onChange={function (value) {
                            settingsStore.changeSetting('autoUpdateHours', {
                                ...settingsStore.settings.autoUpdateHours,
                                action: value,
                            })
                        }}
                        options={[
                            {
                                label: 'Ask Me to',
                                value: 'ask',
                            },
                            {
                                label: 'Always',
                                value: 'automatic',
                            },
                            {
                                label: 'Never',
                                value: 'never',
                            },
                        ]}
                        variant="secondary"
                    />
                    <span>
                        Automatically adjust the Resource Schedule when saving
                        Projects and Time entries.
                    </span>
                </divs>
                <Checkbox
                    value={settingsStore.settings.autoUpdateHours.adjustOnLoad}
                    onChange={function (value) {
                        settingsStore.changeSetting('autoUpdateHours', {
                            ...settingsStore.settings.autoUpdateHours,
                            adjustOnLoad: value,
                        })
                    }}
                    label="Automatically adjust Staff Hours when loading the Resource Schedule"
                    variant="secondary"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={
                                settingsStore.settings.autoUpdateHours.budget
                            }
                            onChange={function (value) {
                                settingsStore.changeSetting('autoUpdateHours', {
                                    ...settingsStore.settings.autoUpdateHours,
                                    budget: value,
                                })
                            }}
                            options={[
                                {
                                    label: 'Total Budget',
                                    value: 'total',
                                },
                                {
                                    label: 'Remaining Budget',
                                    value: 'remaining',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={settingsStore.settings.autoUpdateHours.start}
                            onChange={function (value) {
                                settingsStore.changeSetting('autoUpdateHours', {
                                    ...settingsStore.settings.autoUpdateHours,
                                    start: value,
                                })
                            }}
                            options={[
                                {
                                    label: 'Start Date',
                                    value: 'startDate',
                                },
                                {
                                    label: 'Current Date',
                                    value: 'now',
                                },
                                {
                                    label: 'End Date',
                                    value: 'endDate',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={settingsStore.settings.autoUpdateHours.end}
                            onChange={function (value) {
                                settingsStore.changeSetting('autoUpdateHours', {
                                    ...settingsStore.settings.autoUpdateHours,
                                    end: value,
                                })
                            }}
                            options={[
                                {
                                    label: 'Start Date',
                                    value: 'startDate',
                                },
                                {
                                    label: 'End Date',
                                    value: 'endDate',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                </div>
                <div
                    style={{
                        padding: '4em 0em 1em 0em',
                    }}
                    className="flex flex-align-items-center"
                >
                    <span className="inline-block">
                        Use Invoice Date Type in Reports:
                    </span>
                    <div className="mx-[1em] w-[12em]">
                        <Selector
                            style={{ width: '100%' }}
                            value={settingsStore.settings.reportInvoiceDateType}
                            onChange={function (value) {
                                settingsStore.changeSetting(
                                    'reportInvoiceDateType',
                                    value
                                )
                            }}
                            options={[
                                {
                                    label: 'Work Completion',
                                    value: 'endDate',
                                },
                                {
                                    label: 'Issue Date',
                                    value: 'issueDate',
                                },
                                {
                                    label: 'Due Date',
                                    value: 'dueDate',
                                },
                            ]}
                            variant="secondary"
                        />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: '60em',
                        padding: '0em 0em 2em',
                    }}
                >
                    <p style={{ marginTop: '2em' }}>When saving Invoices:</p>
                    <div
                        style={{
                            marginTop: '1em',
                            marginLeft: '2em',
                        }}
                    >
                        <Checkbox
                            value={settingsStore.settings.savingInvoices.includes(
                                'lockTime'
                            )}
                            onChange={function (value) {
                                if (value) {
                                    settingsStore.changeSetting(
                                        'savingInvoices',
                                        [
                                            'lockTime',
                                            ...settingsStore.settings
                                                .savingInvoices,
                                        ]
                                    )
                                } else {
                                    settingsStore.changeSetting(
                                        'savingInvoices',
                                        settingsStore.settings.savingInvoices.filter(
                                            (s) => s !== 'lockTime'
                                        )
                                    )
                                }
                            }}
                            label="Lock time entries."
                            variant="secondary"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})
