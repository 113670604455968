import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import SessionStore from '../State/SessionStore'
import AppUI from '../Pages/Layout/AppUI'
import LoadingSpinner from '../Components/LoadingSpinner'
import Modals from '../Pages/Layout/Modals'
import { version as appVersion } from '../../package.json'
import ErrorComponent from '../Components/ErrorComponent'
import fetchData from '../Queries/fetchData'
import { qf } from '../Queries/queryFormatter'
import { format, startOfYear } from 'date-fns'
import Page from '../Pages/Layout/Page'
import { migratedToV2 } from '../State/Permissions/HasPermissions'
import { Layout } from '../version2/layout/layout'
import { Switch } from 'antd'
import { useState } from 'react'

const requiredAppData = (userId) => {
    return [
        {
            collection: 'staff',
            fields: [
                'firstName',
                'lastName',
                'permissions',
                'isArchived',
                'email',
            ],
            filters: [`id == "${userId}"`],
        },
        {
            collection: 'organisations',
            fields: [
                'name',
                'accountingSystem',
                'settings',
                'migratedAt',
                'defaultRevenueForecastReportId',
                'defaultResourceScheduleReportId',
                'defaultProjectReportId',
                'defaultStaffReportId',
                'defaultTimeReportId',
                'defaultInvoiceReportId',
                'defaultContactReportId',
                'country',
                ['invNum', 'invoices.invNum'],
                ['invNumYear', 'invoices.invNumYear'],
            ],
            subQueries: [
                {
                    collection: 'invoices',
                    join: `id == invoices.organisationId`,
                    groupBy: ['organisationId'],
                    fields: [
                        ['invNum', 'sum(1)'],
                        [
                            'invNumYear',
                            `sum(issueDate >= ${qf(
                                startOfYear(new Date())
                            )} ? 1 : 0)`,
                        ],
                    ],
                },
            ],
        },
        {
            collection: 'organisationSubscriptions',
            fields: [
                'organisationId',
                'subscribedAt',
                'endsTrialOn',
                'cardExpDate',
                'unpaidInvoices',
                'startedTrialOn',
            ],
        },
        {
            collection: 'projects',
            fields: [
                'jobNumber',
                'name',
                'costCentreId',
                'ownerId',
                ['status', 'phases.status'],
                ['startDate', 'phases.startDate'],
                ['endDate', 'phases.endDate'],
                ['budgetedStaffIds', 'budgetedHours.staffIds'],
                ['monthlyAllocatedStaffIds', 'monthlyAllocations.staffIds'],
            ],
            subQueries: [
                {
                    collection: 'phases',
                    join: `id == phases.projectId`,
                    groupBy: [`projectId`],
                    fields: [
                        [
                            'status',
                            "('active' in arr(status)) ? 'active' : ('prospective' in arr(status)) ? 'prospective' : ('onHold' in arr(status)) ? 'onHold' : 'archived'",
                        ],
                        ['startDate', 'min(startDate)', 'startDate', 'phases'],
                        ['endDate', 'max(endDate)', 'endDate', 'phases'],
                    ],
                    filters: ['isRootPhase != true'],
                },
                {
                    collection: 'budgetedHours',
                    join: `id == budgetedHours.projectId`,
                    groupBy: ['projectId'],
                    fields: [['staffIds', 'uniq(staffId)']],
                    filters: [`staffId != null`],
                },
                {
                    collection: 'monthlyAllocations',
                    join: `id == monthlyAllocations.phaseId`,
                    groupBy: ['phaseId'],
                    fields: [['staffIds', 'uniq(staffId)']],
                    filters: [
                        `staffId != null`,
                        `month >= ${qf(format(new Date(), 'yyyy-MM'))}`,
                        `month <= ${qf(format(new Date(), 'yyyy-MM'))}`,
                    ],
                },
            ],
        },
        {
            collection: 'costCentres',
            fields: ['name', 'isBillable'],
        },
        {
            collection: 'staff',
            fields: ['firstName', 'lastName', 'roleId', 'isArchived'],
        },
        {
            collection: 'roles',
            fields: ['name', 'isArchived'],
        },
        {
            collection: 'timeEntries',
            fields: [
                'costCentreId',
                'projectId',
                'phaseId',
                'staffId',
                'taskId',
                'startMinutes',
                'numMinutes',
                'date',
                'notes',
                'isVariation',
                'isBillable',
                'isOvertime',
                'isLocked',
                'deletedAt',
            ],
            filters: [`staffId == "${userId}"`, `date == ${qf(new Date())}`],
        },
    ].filter(Boolean)
}

export const Route = createFileRoute('/_private')({
    beforeLoad: async ({ location }) => {
        if (!SessionStore.initialized) await SessionStore.initialize()
        if (SessionStore.initialized && !SessionStore.authenticated) {
            throw redirect({
                to: '/login',
                search: {
                    redirect: location.href,
                },
            })
        } else {
            try {
                const data = await Promise.all(
                    requiredAppData(SessionStore.userId).map(fetchData)
                )
                return data
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    throw redirect({
                        to: '/login',
                        search: {
                            redirect: location.href,
                        },
                    })
                } else {
                    // Handle other errors or rethrow them
                    throw error
                }
            }
        }
    },
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
    staleTime: Infinity,
})

function Wrapper() {
    const [v2, setV2] = useState(migratedToV2())
    const isDev = process.env.REACT_APP_ENV === 'development'
    const params = Route.useParams()
    const search = Route.useSearch()
    if (migratedToV2()) {
        return (
            <>
                <Layout>
                    <Outlet />
                </Layout>
                <div
                    className="dont-print"
                    style={{
                        backgroundColor: '#ffffffcc',
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                        position: 'absolute',
                        bottom: '0',
                        right: '1.75em',
                        height: '1.5em',
                        padding: '0 0.5em',
                        borderRadius: '0.25em',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 100,
                    }}
                >
                    {isDev ? (
                        <Switch
                            className="mr-2"
                            checked={v2}
                            size="small"
                            onChange={(v) => {
                                SessionStore.setMigratedToV2(v)
                                setV2(v)
                            }}
                            checkedChildren="V2"
                            unCheckedChildren="V2"
                        />
                    ) : null}
                    {appVersion}
                </div>
            </>
        )
    }
    return (
        <>
            <Modals {...params} {...search} />

            <AppUI>
                <Page>
                    <Outlet />
                </Page>
            </AppUI>
            <div
                className="dont-print"
                style={{
                    backgroundColor: '#ffffffcc',
                    fontWeight: 'bold',
                    fontSize: '0.75em',
                    position: 'absolute',
                    bottom: '0',
                    right: '1.75em',
                    height: '1.5em',
                    padding: '0 0.5em',
                    borderRadius: '0.25em',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 100,
                }}
            >
                {isDev ? (
                    <Switch
                        className="mr-2"
                        checked={v2}
                        size="small"
                        onChange={(v) => {
                            SessionStore.setMigratedToV2(v)
                            setV2(v)
                        }}
                        checkedChildren="V2"
                        unCheckedChildren="V2"
                    />
                ) : null}
                {appVersion}
            </div>
        </>
    )
}
