import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { Avatar, AvatarFallback } from '@2/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { Tabs, TabsList, TabsTrigger } from '@2/components/ui/tabs'
import { Search, MoreVertical } from 'lucide-react'
import { Link, useRouter, useNavigate } from '@tanstack/react-router'

export const TopBar = () => {
    const { latestLocation } = useRouter()
    const navigate = useNavigate()
    return (
        <div className="w-full bg-background">
            <div className="border-b">
                <div className="flex items-center justify-between pl-6 pr-2 mx-auto">
                    <div className="pt-4">
                        <div className="font-semibold text-lg">
                            Project Awesome
                        </div>

                        <div className="flex justify-center mt-2">
                            <Tabs
                                defaultValue="details"
                                className="inline-block"
                                onValueChange={(value) => {
                                    navigate({
                                        to: latestLocation.pathname,
                                        search: (prev) => ({
                                            ...prev,
                                            tab: value,
                                        }),
                                    })
                                }}
                            >
                                <TabsList className="w-full justify-center">
                                    <TabsTrigger value="details">
                                        Details
                                    </TabsTrigger>
                                    <TabsTrigger value="forecasts">
                                        Forecasts
                                    </TabsTrigger>
                                    <TabsTrigger value="tasks">
                                        Tasks
                                    </TabsTrigger>
                                    <TabsTrigger value="changelog">
                                        Changelog
                                    </TabsTrigger>
                                    <TabsTrigger value="rates">
                                        Rates
                                    </TabsTrigger>
                                    <TabsTrigger value="notes">
                                        Notes
                                    </TabsTrigger>
                                    <TabsTrigger value="history">
                                        History
                                    </TabsTrigger>
                                    <TabsTrigger value="status">
                                        Status
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </div>

                    <div className="flex flex-1 justify-end">
                        <div className="mx-4 flex-1 max-w-2xl">
                            <div className="relative">
                                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    placeholder="Search..."
                                    className="pl-8"
                                />
                            </div>
                        </div>

                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="ghost"
                                    className="flex items-center space-x-2"
                                >
                                    <Avatar className="h-8 w-8">
                                        <AvatarFallback className="bg-primary text-primary-foreground">
                                            LC
                                        </AvatarFallback>
                                    </Avatar>
                                    <span>Le Corbusier</span>
                                    <MoreVertical className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                align="end"
                                className="w-[200px]"
                            >
                                <DropdownMenuItem>Profile</DropdownMenuItem>
                                <DropdownMenuItem>Settings</DropdownMenuItem>
                                <DropdownMenuItem>Logout</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            </div>

            {/* Centered Tabs */}
        </div>
    )
}
