import { observable, computed, action, makeObservable } from 'mobx'
import React from 'react'
import _ from 'lodash'
import cuid from 'cuid'
import Formatter from './Formatter'
import EqualityComparator from './EqualityComparator'
import SortComparator from './SortComparator'
import GroupFormatter from './GroupFormatter'
import Aggregator from './Aggregator'
import CellComponent from './CellComponent'

class TableColumnStore {
    id = cuid()
    label = ''
    tableStore = null
    type = 'text'
    value = (row) => String(row[this.id])
    editable = (row) => false
    style = (row) => ({})
    unique = false
    visible = true
    width = '10%'
    onChange = (row, stores) => () => null
    onClick = (row, stores) => () => null
    onFocus = (row, stores) => () => null
    onBlur = (row, stores) => () => null
    data = () => []
    component = null
    className = () => ''
    selected = (row) => false
    permissions = () => true
    print = true

    constructor(data) {
        // makeObservable(this)
        this.update(data)
    }

    update({
        tableStore,
        id,
        label,
        type,
        value,
        editable,
        unique,
        visible,
        onChange,
        onClick,
        onFocus,
        onBlur,
        width,
        data,
        style,
        aggregate,
        cellStyle,
        className,
        component,
        format,
        groupFormat,
        selected,
        permissions,
        print,
    }) {
        // makeObservable(this)
        this.tableStore = tableStore
        this.id = id ?? this.id
        this.label = label ?? this.label
        this.type = type ?? this.type
        this.value = value ?? this.value
        this.editable = editable ?? this.editable
        this.unique = unique ?? this.unique // TODO - for disabling selectors
        this.visible = visible ?? this.visible
        this.width = width ?? this.width
        this.onClick = onClick ?? this.onClick
        this.onChange = onChange ?? this.onChange
        this.onFocus = onFocus ?? this.onFocus
        this.onBlur = onBlur ?? this.onBlur
        this.data = data ?? this.data
        this.style = style ?? this.style
        this.aggregate = aggregate ?? this.aggregate
        this.cellStyle = cellStyle ?? this.cellStyle
        this.className = className ?? this.className
        this.component = component
        this.format = format
        this.groupFormat = groupFormat
        this.selected = selected ?? this.selected
        this.permissions = permissions ?? this.permissions
        this.print = print ?? this.print
    }

    get formatter() {
        return this.format || Formatter[this.type] || (() => '')
    }

    get groupFormatter() {
        return this.groupFormat || GroupFormatter[this.type] || (() => '')
    }

    get csvFormatter() {
        if (this.type.includes('progress')) {
            return ({ numerator, denominator } = {}, options) => {
                if (numerator || denominator) {
                    return `${this.formatter(
                        numerator || 0,
                        options
                    )} / ${this.formatter(denominator || 0, options)})`
                } else {
                    return options?.fallbackValue || ''
                }
            }
        } else {
            return this.formatter
        }
    }

    get csvGroupFormatter() {
        if (this.type.includes('progress')) {
            return ({ numerator, denominator } = {}, options) => {
                if (numerator || denominator) {
                    return `${this.groupFormatter(
                        numerator || 0,
                        options
                    )} / ${this.groupFormatter(denominator || 0, options)})`
                } else {
                    return options?.fallbackValue || ''
                }
            }
        } else {
            return this.groupFormatter
        }
    }

    get equalityComparator() {
        return EqualityComparator[this.type] || (() => -Infinity)
    }

    get sortComparator() {
        return SortComparator[this.type] || (() => -Infinity)
    }

    get aggregator() {
        if (this.aggregate === 'none') return () => null
        return (
            Aggregator[this.type]?.[this.aggregate] ||
            Aggregator[this.type]?.sum ||
            Aggregator[this.type]?.avg ||
            (() => null)
        )
    }

    get sortable() {
        return ['text', 'number'].includes(this.type)
    }

    get filterable() {
        return ['text', 'number'].includes(this.type)
    }

    get groupable() {
        return [
            'project',
            'phase',
            'staff',
            'role',
            'costCentre',
            'status',
            'contact',
        ].includes(this.type)
    }

    get textAlign() {
        switch (this.type) {
            case 'number':
            case 'currency':
            case 'percent':
            case 'durationMinutes':
            case 'date':
                return 'right'
            case 'checkbox':
                return 'center'
            default:
                return 'left'
        }
    }

    get justifyContent() {
        switch (this.type) {
            case 'number':
            case 'currency':
            case 'percent':
            case 'durationMinutes':
            case 'date':
                return 'flex-end'
            case 'checkbox':
                return 'center'
            default:
                return 'flex-start'
        }
    }

    get cellComponent() {
        return this.component || CellComponent[this.type]
    }
}

export default TableColumnStore
