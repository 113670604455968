import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../../Components/Table'
import CashflowChart from '../../../Components/Widgets/CashflowChart'
import ProjectCollection from '../../../State/Collections/ProjectCollection'
import ProjectForecastsStore from './ProjectForecastsStore'
import RenderOnQueries from '../../Layout/RenderOnQueries'
import { queryClient } from '../../../App'
import SessionStore from '../../../State/SessionStore'
import { toJS } from 'mobx'

const spacerColumn = {
    label: '',
    width: 3,
    type: 'text',
    value: (r) => '',
    onChange: (r) => (v) => null,
}

export default observer(({ id }) => {
    return (
        <RenderOnQueries
            queryIds={[
                {
                    id: `forecast${id}${ProjectForecastsStore.periodType}`,
                    baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                    path: `/project-forecast`,
                    method: 'POST',
                    staleTime: 0,
                    cacheTime: 0,
                    data: {
                        projectId: id,
                        organisationId: SessionStore.organisationId,
                        userId: SessionStore.user?.id,
                        invoiceType:
                            SessionStore.organisation
                                .defaultRevenueForecastReport.filters
                                .invoiceData,
                        periodType: ProjectForecastsStore.periodType,
                    },
                },
            ]}
        >
            <ProjectForecastTables id={id} />
        </RenderOnQueries>
    )
})

const ProjectForecastTables = observer(({ id }) => {
    const store = ProjectForecastsStore
    const project = ProjectCollection.projectsById[id]
    const queriesData = queryClient.getQueryData([
        `forecast${project.id}${store.periodType}`,
    ])?.data
    useEffect(() => {
        if (!queriesData.projects.length) {
            queriesData.projects.push({
                ...(project?.serialize() || {}),
                phases: (project?.phases || []).map((p) => ({
                    ...(p?.serialize() || {}),
                    budgets: p.budgets.map((b) => ({
                        ...(b?.serialize() || {}),
                    })),
                    expenses: p.expenses.map((e) => ({
                        ...(e?.serialize() || {}),
                    })),
                })),
            })
        }
        const queryPhaseIds =
            queriesData.projects[0]?.phases?.map((p) => p.id) || []
        if (!project.phases.every((p) => queryPhaseIds.includes(p.id))) {
            queriesData.projects[0].phases ??= []
            project.phases.forEach((p) => {
                if (!queryPhaseIds.includes(p.id)) {
                    queriesData.projects[0].phases.push({
                        ...(p?.serialize() || {}),
                        budgets: p.budgets.map((b) => ({
                            ...(b?.serialize() || {}),
                        })),
                        expenses: p.expenses.map((e) => ({
                            ...(e?.serialize() || {}),
                        })),
                    })
                }
            })
        }
        store.init(queriesData)
    }, [id, store.periodType, queriesData])
    const staffTitle =
        store.cellType === 'currency' ? 'Staff Expenses' : 'Staff Hours'
    return (
        <div>
            <CashflowChart
                style={{ margin: '0 2em' }}
                revenue={store.graphRevenue}
                expenses={store.graphExpenses}
                totalWidth={(18 * 2 + 8 * 11.5) * 10}
                graphWidth={8 * 11 * 10}
                height={22 * 10}
            />
            <Table
                style={{ margin: '2em', marginBottom: 0 }}
                columns={store.totalsTableColumns}
                rows={[{}]}
            />
            {store.showRevenueTable ? (
                <Table
                    style={{ margin: '2em', marginTop: 0 }}
                    columns={store.profitMarginTableColumns}
                    rows={[{}]}
                    showHeader={false}
                />
            ) : null}
            {store.showRevenueTable ? (
                <>
                    <h2 style={{ margin: '2em 0 0 1em' }}>Revenue</h2>
                    <Table
                        style={{ margin: '2em' }}
                        columns={store.revenueTableColumns}
                        rows={store.revenueTableRows}
                        groupBy={
                            store.filterByPhase &&
                            !store.filterByPhase?.isRootPhase
                                ? []
                                : ['project']
                        }
                        expandedGroups={
                            store.filterByPhase &&
                            !store.filterByPhase?.isRootPhase
                                ? []
                                : ['project']
                        }
                        // sortBy={[['label', 'asc']]}
                    />
                </>
            ) : null}
            {store.showStaffTable ? (
                <>
                    <h2 style={{ margin: '2em 0 0 1em' }}>{staffTitle}</h2>
                    <ResourcePhaseTable store={store} />
                </>
            ) : null}
            {store.showExpenseTable ? (
                <>
                    <h2 style={{ margin: '2em 0 0 1em' }}>Project Expenses</h2>
                    <ExpensePhaseTable store={store} />
                </>
            ) : null}
        </div>
    )
})

const ResourcePhaseTable = observer(({ project, store }) => {
    return (
        <Table
            key={store.project?.id + store.cellType}
            style={{ margin: '2em' }}
            columns={store.staffTableColumns((phase) => [
                phase?.id,
                null,
                null,
            ])}
            rows={store.staffPhaseTableRows}
            groupBy={
                store.filterByPhase && !store.filterByPhase?.isRootPhase
                    ? []
                    : ['project']
            }
            expandedGroups={
                store.filterByPhase && !store.filterByPhase?.isRootPhase
                    ? []
                    : ['project']
            }
            getChildComponent={(r) => {
                return <ResourceRoleTable phase={r} store={store} />
            }}
        />
    )
})

const ResourceRoleTable = observer(({ phase, store }) => {
    const level = 3
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    const getChildComponent = (r) => {
        return (
            <ResourceStaffTable
                key={phase?.id + r?.id + store.cellType}
                phase={phase}
                role={r}
                store={store}
            />
        )
    }
    return (
        <Table
            key={phase?.id + store.cellType}
            showHeader={false}
            columns={store.staffTableColumns((role) => [
                phase?.id,
                role?.id,
                null,
            ])}
            rows={store.phaseRoleRowsByPhaseId[phase.id] || []}
            // sortBy={[['label', 'asc']]}
            getChildComponent={getChildComponent}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                fontSize: '1.2rem',
            }}
        />
    )
})

const ResourceStaffTable = observer(({ phase, role, store }) => {
    const columns =
        store.cellType === 'currency'
            ? 'staffTableColumns'
            : 'staffTableHoursColumns'
    const level = 4
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    return (
        <Table
            key={phase?.id + role?.id + store.cellType}
            showHeader={false}
            columns={[
                spacerColumn,
                ...store.staffTableColumns((staff) => [
                    phase?.id,
                    role?.id,
                    staff?.id,
                ]),
            ]}
            rows={store.phaseStaffRowsByPhaseRoleId[phase.id + role.id] || []}
            // sortBy={[['label', 'asc']]}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                fontSize: '1.2rem',
            }}
        />
    )
})

const ExpensePhaseTable = observer(({ store }) => {
    return (
        <Table
            key={'expenseTable'}
            style={{ margin: '2em' }}
            rows={store.expenseTableRows}
            columns={store.expenseTableColumns((phase) => [phase.id, null])}
            groupBy={
                store.filterByPhase && !store.filterByPhase?.isRootPhase
                    ? []
                    : ['project']
            }
            expandedGroups={
                store.filterByPhase && !store.filterByPhase?.isRootPhase
                    ? []
                    : ['project']
            }
            // sortBy={[['label', 'asc']]}
            getChildComponent={(r) => {
                return <ExpenseExpenseTable phase={r} store={store} />
            }}
        />
    )
})

const ExpenseExpenseTable = observer(({ phase, store }) => {
    const level = 3
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    return (
        <Table
            key={'expenseTable' + phase.id}
            showHeader={false}
            columns={[
                spacerColumn,
                ...store.expenseTableColumns((expenseName) => [
                    phase.id,
                    expenseName,
                ]),
            ]}
            rows={store.expensePhaseTableRows(phase.id)}
            // sortBy={[['label', 'asc']]}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                fontSize: '1.2rem',
            }}
        />
    )
})
