import React, { useRef, useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import useMouse from '@react-hook/mouse-position'
import {
    format,
    startOfWeek,
    endOfWeek,
    addDays,
    addWeeks,
    subWeeks,
    differenceInDays,
    isSameMonth,
    getWeek,
    isSameWeek,
} from 'date-fns'
import calendarState from './CalendarState'
import 'react-calendar/dist/Calendar.css'
import { useGesture } from 'react-use-gesture'
import _ from 'underscore'
import { Button } from 'antd'
import SessionStore from '../../State/SessionStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import tuple from 'immutable-tuple'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import DailyAllocationCollection from '../../State/Collections/DailyAllocationCollection'
import DataStore from '../../State/DataStore'
import LayoutStore from '../../State/LayoutStore'
import { toJS } from 'mobx'
import {
    canEditStaffTime,
    canEditStaffAllocations,
} from '../../State/Permissions/HasPermissions'
import { StaffSelector } from '../Selector'
import DateValue from '../Widgets/DateValue'
import classNames from 'classnames'
import { useNavigate } from '@tanstack/react-router'

export const Calendar = observer(({ mode = 'time' }) => {
    const navigate = useNavigate()
    useEffect(() => {
        LayoutStore.toggleSidebar(true)
    }, [])
    const settings = SessionStore.organisation.settings
    const [showCal, setShowCal] = useState(false)
    const ref = React.useRef()
    const mouse = useMouse(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    })
    const calDrag = useGesture({
        onDragStart: (state) => {
            calendarState.deselectItem()
            calendarState.setCalendarMouseData(
                mouse.x,
                mouse.y,
                mouse.elementWidth,
                mouse.elementHeight
            )
        },
        onDrag: (state) => {
            if (!calendarState.newItem) {
                if (
                    state.movement[1] / (calendarState.calendarMinuteEms * 10) >
                    7.5
                ) {
                    calendarState.createNewItem()
                }
            } else {
                const numMinutes =
                    calendarState.calendarMouseFloatMinutes -
                    calendarState.newItem.startMinutes
                calendarState.updateNewItem({
                    numMinutes,
                })
            }
        },
        onDragEnd: (state) => {
            if (calendarState.newItem) {
                let numMinutes =
                    calendarState.calendarMouseFloatMinutes -
                    calendarState.newItem.startMinutes
                numMinutes = Math.round(numMinutes / 15) * 15
                calendarState.commitNewItem({
                    numMinutes,
                })
            }
        },
    })
    useEffect(() => {
        // scroll calendar to 7:30am
        calendarState.init(mode)
        ref.current.parentNode.scrollTop =
            calendarState.calendarMinuteEms * 7.75 * 60 * 16
        DataStore.setAutosave(true)
        return () => DataStore.setAutosave(false)
    }, [])
    const weekEndSameMonth = isSameMonth(
        calendarState.weekStart,
        calendarState.weekEnd
    )
    return (
        <div className="calendar" style={{ fontSize: '1rem' }}>
            <div className="not-calendar" style={{ textAlign: 'center' }}>
                <h3>{`${
                    mode === 'time' ? 'Time Entry Calendar' : 'Weekly Planning'
                }`}</h3>
                <h1>
                    {`${format(
                        calendarState.weekStart,
                        weekEndSameMonth ? 'dd' : 'dd MMM'
                    )}${weekEndSameMonth ? '-' : ' - '}${format(
                        calendarState.weekEnd,
                        weekEndSameMonth ? 'dd MMMM yyyy' : 'dd MMM yyyy'
                    )}`}
                </h1>
                <div className="flex items-center justify-center">
                    <button
                        className="ant-btn"
                        onClick={(e) => {
                            calendarState.setWeekStart(
                                subWeeks(calendarState.weekStart, 1, {
                                    weekStartsOn: 1,
                                })
                            )
                        }}
                    >
                        {'<'}
                    </button>
                    <DateValue
                        value={calendarState.weekStart}
                        onChange={(date) => {
                            calendarState.setWeekStart(date)
                        }}
                        style={{ width: '10rem', display: 'inline-block' }}
                    />
                    <button
                        className="ant-btn"
                        onClick={(e) => {
                            calendarState.setWeekStart(
                                addWeeks(calendarState.weekStart, 1, {
                                    weekStartsOn: 1,
                                })
                            )
                        }}
                    >
                        {'>'}
                    </button>
                </div>
            </div>
            {(mode === 'time' && canEditStaffTime(SessionStore.user)) ||
            (mode === 'allocation' &&
                canEditStaffAllocations(SessionStore.user)) ? (
                <div className="not-calendar pl-[4rem] pb-[1rem] max-w-max">
                    <StaffSelector
                        className="timesheet__staff-selector"
                        selectedStaffMember={
                            StaffCollection.staffById[
                                calendarState.selectedValues.staffId
                            ]
                        }
                        allowNull={false}
                        onChange={(staff) => {
                            calendarState.updateSelectedStaffId(staff.id)
                            navigate({
                                search: (prev) => ({
                                    ...prev,
                                    staffId: staff.id,
                                }),
                            })
                        }}
                        variant="secondary"
                    />
                </div>
            ) : null}
            <div
                className="flex flex-col"
                style={{
                    height: `${window.innerHeight - 150}px`,
                }}
            >
                <div className="flex" style={{ flex: '0 0 auto' }}>
                    <div
                        className="w-[4rem]"
                        style={{
                            flex: '0 0 auto',
                            height: `${calendarState.calendarMinuteEms * 55}rem`,
                        }}
                    />
                    <div
                        className={classNames(
                            'flex flex-1 border border-[#ddd]',
                            mode === 'time' ? 'bg-[#f0f0f0]' : 'bg-[#666]'
                        )}
                        ref={ref}
                        style={{
                            height: `${calendarState.calendarMinuteEms * 55}rem`,
                            borderRadius: '1rem 1rem 0 0',
                        }}
                    >
                        {calendarState.weekDays.map((day, i) => {
                            return (
                                <div
                                    className="flex items-center justify-center h-full text-center"
                                    style={{
                                        width: `${100 / 7}%`,
                                        color: [5, 6].includes(i)
                                            ? mode === 'time'
                                                ? '#888'
                                                : '#ccc'
                                            : mode === 'time'
                                              ? '#444'
                                              : '#fff',
                                    }}
                                >
                                    <div>
                                        <div className="text-[1.75rem] mt-1.5 font-semibold">
                                            {`${format(day, 'dd')}`}
                                        </div>
                                        <div className="uppercase text-[0.8rem] mt-1.5 font-normal">
                                            {`${format(day, 'EEEE')}`}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex" style={{ flex: '0 0 auto' }}>
                    <div
                        className="w-[4rem]"
                        style={{
                            flex: '0 0 auto',
                            height: `${calendarState.calendarMinuteEms * 20}rem`,
                        }}
                    />
                    <div
                        ref={ref}
                        className="flex flex-1 border-b border-b-[#ddd] bg-[#f0f0f0]"
                        style={{
                            height: `${calendarState.calendarMinuteEms * 20}rem`,
                        }}
                    >
                        {calendarState.weekDays.map((day, i) => {
                            return (
                                <div
                                    className="h-full text-center flex items-center justify-center"
                                    style={{
                                        width: `${100 / 7}%`,
                                        color: [5, 6].includes(i)
                                            ? '#888'
                                            : '#444',
                                    }}
                                >
                                    <div>
                                        <div className="text-sm font-extrabold">
                                            {`${
                                                calendarState.dayTotals[i].hours
                                            }:${
                                                (calendarState.dayTotals[i]
                                                    .minutes < 10
                                                    ? '0'
                                                    : '') +
                                                calendarState.dayTotals[i]
                                                    .minutes
                                            }`}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-1 overflow-auto">
                    <svg
                        className="w-[4em] bg-transparent"
                        style={{
                            flex: '0 0 auto',
                            height: `${
                                calendarState.calendarMinuteEms * 60 * 24
                            }rem`,
                        }}
                    >
                        {[...Array(24 - 1).keys()].map((d) => {
                            const h24 = d + 1
                            const h12 = h24 > 12 ? h24 - 12 : h24
                            const ampm = h24 >= 12 ? 'pm' : 'am'
                            return (
                                <text
                                    style={{
                                        fontSize: '1rem',
                                        fill: '#888',
                                    }}
                                    x={`85%`}
                                    y={`${
                                        (d + 1) *
                                            calendarState.calendarMinuteEms *
                                            60 +
                                        0.25
                                    }rem`}
                                    textAnchor={`end`}
                                >{`${h12} ${ampm}`}</text>
                            )
                        })}
                    </svg>
                    <div
                        className="flex-1"
                        style={{
                            height: `${
                                calendarState.calendarMinuteEms * 60 * 24
                            }rem`,
                        }}
                        ref={ref}
                        onPointerMove={(e) => {
                            calendarState.setCalendarMouseData(
                                mouse.x,
                                mouse.y,
                                mouse.elementWidth,
                                mouse.elementHeight
                            )
                        }}
                    >
                        {(
                            DailyAllocationCollection
                                .dailyAllocationsByStaffIdWeek[
                                tuple(
                                    calendarState.selectedValues.staffId,
                                    getWeek(calendarState.weekStart, {
                                        weekStartsOn: 1,
                                    })
                                )
                            ] || []
                        ).map((al) => {
                            return (
                                <CalendarAllocation
                                    key={al.id}
                                    allocation={al}
                                    mouse={mouse}
                                />
                            )
                        })}
                        {(
                            TimeEntryCollection.timeEntries.filter(
                                (te) =>
                                    te.numMinutes > 0 &&
                                    te.staffId ===
                                        calendarState.selectedValues.staffId &&
                                    isSameWeek(
                                        te.date,
                                        calendarState.weekStart,
                                        { weekStartsOn: 1 }
                                    ) &&
                                    !te.deletedAt
                            ) || []
                        ).map((te) => {
                            return (
                                <CalendarTimeEntry
                                    key={te.id}
                                    timeEntry={te}
                                    mouse={mouse}
                                />
                            )
                        })}
                        {calendarState.newItem &&
                            (calendarState.mode === 'allocation' ? (
                                <CalendarAllocation
                                    key={calendarState.newItem.id}
                                    allocation={calendarState.newItem}
                                    mouse={mouse}
                                />
                            ) : (
                                <CalendarTimeEntry
                                    key={calendarState.newItem.id}
                                    timeEntry={calendarState.newItem}
                                    mouse={mouse}
                                />
                            ))}
                        <svg
                            style={{
                                height: `${
                                    calendarState.calendarMinuteEms * 60 * 24
                                }rem`,
                                width: '100%',
                                background: 'white',
                                borderRadius: '0 0 1rem 1rem',
                                zIndex: 1,
                                fontSize: '1rem',
                            }}
                            onClick={(e) => {
                                calendarState.deselectItem()
                            }}
                            {...calDrag()}
                        >
                            <defs>
                                <pattern
                                    id="hatchtile"
                                    x="-5"
                                    y="-5"
                                    patternUnits="userSpaceOnUse"
                                    height="5"
                                    width="5"
                                >
                                    <image
                                        x="0"
                                        y="0"
                                        height="5"
                                        width="5"
                                        xlinkHref={`${new URL(
                                            '/public/hatchtile.png',
                                            import.meta.url
                                        )}`}
                                    />
                                </pattern>
                            </defs>
                            {[...Array(7).keys()].map((d) => {
                                return calendarState.isDayIndexWeekend(d) ? (
                                    <rect
                                        width={`${100 / 7}%`}
                                        height="100%"
                                        x={`${d * (100 / 7)}%`}
                                        y="0"
                                        fill="url(#hatchtile)"
                                        opacity="1"
                                    />
                                ) : null
                            })}
                            {[...Array((24 * 60) / 15 - 1).keys()].map((d) => {
                                return (
                                    <line
                                        x1={`0%`}
                                        y1={`${
                                            (d + 1) *
                                            calendarState.calendarMinuteEms *
                                            15
                                        }rem`}
                                        x2={`100%`}
                                        y2={`${
                                            (d + 1) *
                                            calendarState.calendarMinuteEms *
                                            15
                                        }rem`}
                                        stroke={
                                            (d + 1) % 4 ? '#f0f0f0' : '#ddd'
                                        }
                                    />
                                )
                            })}
                            {[...Array(6).keys()].map((d) => {
                                return (
                                    <line
                                        x1={`${(d + 1) * (100 / 7)}%`}
                                        y1="0%"
                                        x2={`${(d + 1) * (100 / 7)}%`}
                                        y2="100%"
                                        stroke="#ddd"
                                    />
                                )
                            })}
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
})

const CalendarAllocation = observer(({ allocation, mouse }) => {
    return (
        <CalendarBlock
            item={allocation}
            itemType="allocation"
            zIndex={calendarState.mode === 'allocation' ? 3 : 2}
            align={'left'}
            opacity={calendarState.mode === 'allocation' ? 1 : 0.5}
            mouse={mouse}
        />
    )
})

const CalendarTimeEntry = observer(({ timeEntry, mouse }) => {
    return (
        <CalendarBlock
            key={timeEntry.id}
            item={timeEntry}
            itemType="time"
            zIndex={calendarState.mode === 'time' ? 3 : 2}
            align={'right'}
            opacity={calendarState.mode === 'time' ? 1 : 0.5}
            mouse={mouse}
        />
    )
})

const CalendarBlock = observer(
    ({ item, itemType, zIndex, align, opacity, mouse, ...props }) => {
        const disabled = itemType !== calendarState.mode
        const settings = SessionStore.organisation.settings
        const diffDays = differenceInDays(item.date, calendarState.weekStart)
        const [hovering, setHovering] = useState(false)
        const [changeY, setChangeY] = useState(0)
        const [changeLength, setChangeLength] = useState(0)
        const y = changeY + item.startMinutes
        const length = changeLength + item.numMinutes
        const selected = item === calendarState.selectedItem
        const startDrag = useGesture({
            onDragStart: () => {
                calendarState.setDragging(true)
                calendarState.selectItem(item, itemType)
            },
            onDrag: (state) => {
                const newY = calendarState.calendarMouseFloatMinutes
                const newLength = length - (newY - y)
                setChangeY(newY - item.startMinutes)
                calendarState.adjustDragMinutes(newLength - item.numMinutes)
                setChangeLength(newLength - item.numMinutes)
            },
            onDragEnd: (state) => {
                let oldStartMniutes = item.startMinutes
                let newStartMinutes = changeY + item.startMinutes
                // newNumMinutes = Math.round(newNumMinutes / 15) * 15;
                newStartMinutes = Math.round(newStartMinutes / 15) * 15
                let newNumMinutes =
                    item.numMinutes - (newStartMinutes - oldStartMniutes)
                calendarState.editSelectedItem({
                    startMinutes: newStartMinutes,
                    numMinutes: newNumMinutes,
                })
                setChangeY(0)
                calendarState.adjustDragMinutes(0)
                setChangeLength(0)
                calendarState.setDragging(false)
            },
        })
        const endDrag = useGesture({
            onDragStart: () => {
                calendarState.setDragging(true)
                calendarState.selectItem(item, itemType)
            },
            onDrag: (state) => {
                const newLength = calendarState.calendarMouseFloatMinutes - y
                calendarState.adjustDragMinutes(newLength - item.numMinutes)
                setChangeLength(newLength - item.numMinutes)
            },
            onDragEnd: (state) => {
                let newNumMinutes = changeLength + item.numMinutes
                newNumMinutes = Math.round(newNumMinutes / 15) * 15
                calendarState.editSelectedItem({
                    numMinutes: newNumMinutes,
                })
                calendarState.adjustDragMinutes(0)
                setChangeLength(0)
                calendarState.setDragging(false)
            },
        })
        const calDrag = useGesture({
            onDragStart: (state) => {
                calendarState.deselectItem()
                calendarState.setCalendarMouseData(
                    mouse.x,
                    mouse.y,
                    mouse.elementWidth,
                    mouse.elementHeight
                )
            },
            onDrag: (state) => {
                if (!calendarState.newItem) {
                    if (
                        state.movement[1] /
                            (calendarState.calendarMinuteEms * 10) >
                        7.5
                    ) {
                        calendarState.createNewItem()
                    }
                } else {
                    const numMinutes =
                        calendarState.calendarMouseFloatMinutes -
                        calendarState.newItem.startMinutes
                    calendarState.updateNewItem({
                        numMinutes,
                    })
                }
            },
            onDragEnd: (state) => {
                if (calendarState.newItem) {
                    let numMinutes =
                        calendarState.calendarMouseFloatMinutes -
                        calendarState.newItem.startMinutes
                    numMinutes = Math.round(numMinutes / 15) * 15
                    calendarState.commitNewItem({
                        numMinutes,
                    })
                }
            },
        })
        return (
            diffDays < 7 &&
            diffDays >= 0 && (
                <div
                    className={
                        `calendar-item ${align}` +
                        (selected ? ' selected' : '') +
                        (itemType === 'allocation' ? ' allocation' : '')
                    }
                    style={{
                        width: `calc(${100 / 7}% - 2rem - 2px)`,
                        height: `calc(${
                            calendarState.calendarMinuteEms * length
                        }rem - 2px)`,
                        left:
                            align === 'left'
                                ? `calc(${(100 / 7) * diffDays}% + 1px)`
                                : `calc(${(100 / 7) * diffDays}% + 2rem + 1px)`,
                        top: `calc(${
                            calendarState.calendarMinuteEms * y
                        }rem + 1px)`,
                        zIndex:
                            zIndex +
                            (selected
                                ? 3
                                : hovering && !calendarState.dragging
                                  ? 2
                                  : 0),
                        opacity:
                            opacity +
                            ((selected || hovering) && !calendarState.dragging
                                ? 0.4
                                : 0),
                    }}
                    onPointerOver={(e) => setHovering(true)}
                    onPointerOut={(e) => setHovering(false)}
                    onClick={(e) => {
                        calendarState.selectItem(item, itemType)
                    }}
                    {...(calendarState.mode === 'time' &&
                    itemType === 'allocation'
                        ? calDrag()
                        : {})}
                    {...props}
                >
                    {!disabled && (
                        <svg
                            className="vertical-drag"
                            style={{
                                width: '100%',
                                height: '1rem',
                                position: 'absolute',
                                top: '-0.5rem',
                                left: 0,
                            }}
                            {...startDrag()}
                        ></svg>
                    )}
                    {!disabled && (
                        <svg
                            className="vertical-drag"
                            style={{
                                width: '100%',
                                height: '1rem',
                                position: 'absolute',
                                bottom: '-0.5rem',
                                left: 0,
                            }}
                            {...endDrag()}
                        ></svg>
                    )}
                    <div style={{ fontSize: '0.65rem' }}>
                        {item.project?.title}
                    </div>
                    <div style={{ fontSize: '0.65rem' }}>
                        {item.projectPhase?.title}
                    </div>
                    {settings.useTasks && (
                        <div style={{ fontSize: '0.65rem' }}>
                            {item.task?.name}
                        </div>
                    )}
                    <div
                        className="time"
                        style={{ pointerEvents: 'none' }}
                    >{`${Math.floor(
                        Math.round(item.numMinutes + changeLength) / 60
                    )}:${
                        Math.round(item.numMinutes + changeLength) % 60 < 10
                            ? (Math.round(item.numMinutes + changeLength) %
                                  60) +
                              '0'
                            : Math.round(item.numMinutes + changeLength) % 60
                    }`}</div>
                </div>
            )
        )
    }
)
