import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import {
    PhaseSelector,
    ProjectSelector,
    StaffSelector,
} from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import ResourceScheduleStore from './ResourceScheduleStore'
import { StaffMultiselect } from '../../Components/MultiSelect'

export default observer(({ report, modalId }) => {
    const [project, setProject] = useState()
    const [phase, setPhase] = useState()
    const [staffs, setStaffs] = useState([])
    return (
        <Modal
            modalId={modalId}
            heading="Add Resource Allocations"
            onSave={() =>
                ResourceScheduleStore.addStaffs(staffs, project, phase)
            }
            saveLabel="Add Allocations"
            canSave={() => !!staffs.length && !!project && !!phase}
            style={{ minHeight: '20em' }}
        >
            <div style={{ position: 'fixed', width: '34em' }}>
                <StaffMultiselect
                    selectedStaffMembers={staffs}
                    onChange={(s) => setStaffs(s)}
                />
                <ProjectSelector
                    selectedProject={project}
                    onChange={(pr) => setProject(pr)}
                    variant="secondary"
                />
                {project && (
                    <RenderOnQueries
                        queryIds={[
                            project
                                ? {
                                      id: `phases-${project.id}`,
                                      collection: 'phases',
                                      fields: [
                                          'jobNumber',
                                          'name',
                                          'projectId',
                                          'status',
                                          'startDate',
                                          'endDate',
                                          'isRootPhase',
                                      ],
                                      filters: [`projectId == "${project.id}"`],
                                  }
                                : null,
                        ].filter((v) => v)}
                    >
                        <PhaseSelector
                            selectedPhase={phase}
                            phaseOptions={project.phases}
                            onChange={(ph) => setPhase(ph)}
                            variant="secondary"
                        />
                    </RenderOnQueries>
                )}
            </div>
            <div style={{ width: '34em', visibility: 'hidden' }}>
                <StaffMultiselect
                    selectedStaffMembers={staffs}
                    onChange={(s) => setStaffs(s)}
                />
                <ProjectSelector
                    selectedProject={project}
                    onChange={(pr) => setProject(pr)}
                />
                {project && (
                    <RenderOnQueries
                        queryIds={[
                            project
                                ? {
                                      id: `phases-${project.id}`,
                                      collection: 'phases',
                                      fields: [
                                          'jobNumber',
                                          'name',
                                          'projectId',
                                          'status',
                                          'startDate',
                                          'endDate',
                                          'isRootPhase',
                                      ],
                                      filters: [`projectId == "${project.id}"`],
                                  }
                                : null,
                        ].filter((v) => v)}
                    >
                        <PhaseSelector
                            selectedPhase={phase}
                            phaseOptions={project.phases}
                            onChange={(ph) => setPhase(ph)}
                        />
                    </RenderOnQueries>
                )}
            </div>
        </Modal>
    )
})
