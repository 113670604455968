import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Modal from '../../Components/Modal'
import { Selector } from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import Table from '../../Components/Table'
import { observable, computed, action, makeObservable } from 'mobx'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import _ from 'lodash'

const dropdownOptions = {
    any: { label: 'any', value: null },
    yes: { label: 'yes', value: true },
    no: { label: 'no', value: false },
}
class state {
    @observable selectedExpenses = new Set()
    constructor() {
        makeObservable(this)
    }
    @action selectExpense(e) {
        this.selectedExpenses.add(e)
    }
    @action deselectExpense(e) {
        this.selectedExpenses.delete(e)
    }
    @action clearSelectedExpenses() {
        this.selectedExpenses.clear()
    }
}
const State = new state()

export default observer(({ modalId, invoice, project, phase }) => {
    const [billable, setBillable] = useState('yes')
    const [inDateRange, setInDateRange] = useState('yes')
    const [remainingCost, setRemainingCost] = useState('yes')
    const expenses = (phase?.expenses || project.expenses).filter((e) => {
        return (
            (dropdownOptions[billable].value == null ||
                e.billable === dropdownOptions[billable].value) &&
            (dropdownOptions[inDateRange].value == null ||
                (e.startDate <= invoice.endDate &&
                    e.endDate >= invoice.startDate) ===
                    dropdownOptions[inDateRange].value) &&
            (dropdownOptions[remainingCost].value == null ||
                (!e.beenInvoiced &&
                    e.cost -
                        (invoice.cachedData.expenses[e.id]?.previousBilled ||
                            0) >
                        0) === dropdownOptions[remainingCost].value)
        )
    })
    const expenseColumns = [
        {
            id: 'selected',
            label: '',
            width: 3,
            type: 'checkbox',
            editable: (r) => true,
            value: (r) => {
                return State.selectedExpenses.has(r)
            },
            onChange: (r) => (v) => {
                State.selectedExpenses.has(r)
                    ? State.deselectExpense(r)
                    : State.selectExpense(r)
            },
        },
        {
            id: 'expense',
            label: 'Expense',
            width: 16,
            type: 'text',
            editable: (r) => false,
            value: (r) => r.name,
        },
        {
            id: 'startDate',
            label: 'Start Date',
            width: 10,
            type: 'date',
            editable: (r) => false,
            value: (r) => r.startDate,
        },
        {
            id: 'endDate',
            label: 'End Date',
            width: 10,
            type: 'date',
            editable: (r) => false,
            value: (r) => r.endDate,
        },
        {
            id: 'remainingCost',
            label: 'Remaining Cost',
            width: 10,
            type: 'currency',
            editable: (r) => false,
            value: (r) => {
                const expenseLineItems = invoice.lineItems.filter(
                    (li) => li.expenseId === r.id
                )
                return (
                    r.cost -
                    (invoice.cachedData.expenses[r.id]?.previousBilled || 0) -
                    _.sum(expenseLineItems.map((li) => li.amount || 0))
                )
            },
        },
        {
            id: 'totalCost',
            label: 'Total Cost',
            width: 10,
            type: 'currency',
            editable: (r) => false,
            value: (r) => r.cost,
        },
    ]
    return (
        <Modal
            modalId={modalId}
            heading="Add Expense Line Items"
            bodyStyle={{ padding: 0 }}
            width="50em"
            saveLabel={'Add Expenses'}
            onSave={() => {
                State.selectedExpenses.forEach((e) => {
                    InvoiceLineItemCollection.add(
                        {
                            projectId: invoice.projectId,
                            contactId: invoice.contactId,
                            invoiceId: invoice.id,
                            phaseId: e.phase?.id || invoice.project.rootPhaseId,
                            billingType: 'reimbursement',
                            lineItemType: 'expense',
                            description: e.name,
                            unitQuantity: 1,
                            unitCost:
                                e.cost -
                                (invoice.cachedData.expenses[e.id]
                                    ?.previousBilled || 0),
                            expenseId: e.id,
                            isTaxed: true,
                        },
                        { trackUpdates: true }
                    )
                })
                State.clearSelectedExpenses()
            }}
        >
            <RenderOnQueries
                queryIds={[
                    {
                        collection: 'projectExpenses',
                        fields: [
                            'projectId',
                            'phaseId',
                            'cost',
                            'startDate',
                            'endDate',
                            'name',
                            'quantity',
                            'unitCost',
                            'billable',
                            'beenInvoiced',
                        ],
                        filters: [`projectId == "${project.id}"`],
                    },
                ]}
            >
                <div className="flex items-center justify-around p-[1em] border-b border-[#ccc]">
                    <div className="flex items-center">
                        <span
                            className="flex-0-0-auto"
                            style={{ marginRight: '1em' }}
                        >
                            Billable:
                        </span>
                        <Selector
                            selectedOption={dropdownOptions[billable]}
                            onChange={(o) => setBillable(o)}
                            options={Object.keys(dropdownOptions)}
                            optionLabel={(o) => o?.label || o}
                            style={{ width: '5.5em' }}
                            variant="secondary"
                            className="[&_span]:!text-xs"
                        />
                    </div>
                    <div className="flex items-center">
                        <span
                            className="flex-0-0-auto"
                            style={{ marginRight: '1em' }}
                        >
                            In Date Range:
                        </span>
                        <Selector
                            selectedOption={dropdownOptions[inDateRange]}
                            onChange={(o) => setInDateRange(o)}
                            options={Object.keys(dropdownOptions)}
                            optionLabel={(o) => o?.label || o}
                            style={{ width: '5.5em' }}
                            variant="secondary"
                            className="[&_span]:!text-xs"
                        />
                    </div>
                    <div className="flex items-center">
                        <span
                            className="flex-0-0-auto"
                            style={{ marginRight: '1em' }}
                        >
                            Remaining Cost:
                        </span>
                        <Selector
                            selectedOption={dropdownOptions[remainingCost]}
                            onChange={(o) => setRemainingCost(o)}
                            options={Object.keys(dropdownOptions)}
                            optionLabel={(o) => o?.label || o}
                            style={{ width: '5.5em' }}
                            variant="secondary"
                            className="[&_span]:!text-xs"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center p-[1.5em]">
                    <Table
                        showHeader={true}
                        columns={expenseColumns}
                        rows={expenses}
                    />
                </div>
            </RenderOnQueries>
        </Modal>
    )
})
