import moment from 'moment'
import _ from 'underscore'
import React from 'react'
import CreateReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { DropdownList } from 'react-widgets'
import PropTypes from 'prop-types'
export { NumberInputContainer } from './NumberInputContainer.js'
export { FilterTextBox } from './FilterTextBox.js'
export { Checkbox } from './Checkbox.js'

export var RadioButton = CreateReactClass({
    /**
    <RadioButton
      valueLink={this.linkState('variable')}
      identifier="value1"
    />

    is a shortcut for

    <RadioButton
      value={this.state.variable === 'value1'}
      onChange={function(checked) {
        if (checked) {
          self.setState({variable: 'value1'});
        }
      }}
    />
  */

    propTypes: {
        label: PropTypes.node,

        value: PropTypes.bool,

        // `onChange` and `onSelected` are identical; `onSelected` is a better name
        // but `onChange` still exists for backward compatibility.
        onChange: PropTypes.func,
        onSelected: PropTypes.func,

        valueLink: PropTypes.object,
        identifier: PropTypes.any,

        className: PropTypes.string,
        name: PropTypes.string,

        disabled: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            className: '',
            disabled: false,
        }
    },

    getInitialState: function () {
        return {}
    },

    render: function () {
        let value =
            this.props.valueLink != null
                ? this.props.valueLink.value === this.props.identifier
                : this.props.value
        let disabled = this.props.disabled
        //TODO-project_architect make this accessible
        return (
            <label className={`coincraft-radio-button ${this.props.className}`}>
                <div
                    className={classNames('coincraft-radio-button__square', {
                        checked: value,
                    })}
                    style={{ cursor: disabled ? 'default' : 'pointer' }}
                >
                    <input
                        className="coincraft-radio-button__input"
                        type="radio"
                        name={this.props.name}
                        checked={value || ''}
                        onChange={this.handleChange}
                        required={true}
                        disabled={disabled}
                    />
                    <ins className="coincraft-radio-button__helper" />
                </div>
                {this.props.label}
            </label>
        )
    },

    handleChange: function (event) {
        if (!this.props.disabled) {
            if (this.props.valueLink != null) {
                this.props.valueLink.requestChange(this.props.identifier)
            } else {
                if (this.props.onChange != null) {
                    this.props.onChange(event.target.checked, event)
                }
                if (this.props.onSelected != null) {
                    this.props.onSelected(event.target.checked, event)
                }
            }
        }
    },
})

export var SmallDeleteButton = CreateReactClass({
    propTypes: {
        className: PropTypes.string,
    },

    getDefaultProps: function () {
        return {
            className: '',
        }
    },

    render: function () {
        var { className, ...props } = this.props
        return (
            <button
                className={classNames(
                    'btn',
                    'btn-default',
                    'btn-sm',
                    'delete-button',
                    'size-[30px] flex items-center justify-center disabled:text-[#ccc]',
                    className
                )}
                {...props}
            >
                <i className="fa fa-times" style={{ marginRight: 0 }} />
            </button>
        )
    },
})

export var Dropdown3 = CreateReactClass({
    propTypes: {
        isExpanded: PropTypes.bool,
        onToggle: PropTypes.func,
        contentStyle: PropTypes.any,
        containerStyle: PropTypes.any,

        // Optional. If provided, the `Dropdown3ListItem` children are wrapped so
        // when one is clicked, this function is called with the list item's
        // `identifier` property as the single argument. If not provided it's up to
        // the user to attach `onClick` handlers as they see fit.
        onItemClick: PropTypes.func,

        // If true, open to the left, else, open to the right.
        dropLeft: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            onToggle: function (isExpanded) {},
            dropLeft: false,
        }
    },

    getInitialState: function () {
        return {
            isExpanded: false,
        }
    },

    componentWillReceiveProps(nextProps) {
        if (nextProps.isExpanded && !this.props.isExpanded) {
            this.addBodyClickHandler()
        }
    },

    componentWillUnmount() {
        window.$('body').off('click', this.handleBodyClick)
    },

    render: function () {
        let self = this

        let mainContentStyle = {
            position: 'absolute',
            top: '100%',
            zIndex: 100,
            padding: 0,
            [this.props.dropLeft ? 'right' : 'left']: 0,
        }

        return (
            <div
                style={{
                    display: 'inline-block',
                    ...this.props.containerStyle,
                }}
                className={this.props.className}
            >
                <div style={{ position: 'relative' }}>
                    {React.cloneElement(this.props.toggleElement, {
                        onClick: this.handleToggleButtonClick,
                    })}
                    {this.isExpanded() ? (
                        <div style={mainContentStyle}>
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    padding: 0,
                                    fontSize: 13,
                                    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
                                    border: '1px solid rgba(0,0,0,.15)',
                                    textAlign: 'left',
                                    marginTop: '0.2em',
                                    ...this.props.contentStyle,
                                }}
                            >
                                {this.props.children.map(function (child, i) {
                                    if (child == null) {
                                        return null
                                    }
                                    return (
                                        <Dropdown3ListItemWrapper
                                            key={i}
                                            onClick={() =>
                                                self.handleMenuItemClick(child)
                                            }
                                            disabled={child.props.disabled}
                                            item={child}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    },

    handleMenuItemClick: function (child, event) {
        if (this.props.onItemClick != null) {
            this.props.onItemClick(child.props.identifier, event)
        }
        this.setExpanded(false)
    },

    addBodyClickHandler: function () {
        window.$('body').one('click', this.handleBodyClick)
    },

    isExpanded: function () {
        if (this.props.isExpanded != null) {
            return this.props.isExpanded
        } else {
            return this.state.isExpanded
        }
    },

    setExpanded: function (isExpanded) {
        if (isExpanded !== this.isExpanded()) {
            if (this.props.isExpanded != null) {
                this.props.onToggle(isExpanded)
            } else {
                this.setState({ isExpanded: isExpanded })
            }
            if (isExpanded) {
                this.addBodyClickHandler()
            }
        }
    },

    handleToggleButtonClick: function (event) {
        this.setExpanded(!this.isExpanded())
        event.stopPropagation()
    },

    handleBodyClick(event) {
        if (
            !_.include(
                window.$(event.target).parents(),
                ReactDOM.findDOMNode(this)
            )
        ) {
            this.setExpanded(false)
        }
    },
})

var Dropdown3ListItemWrapper = CreateReactClass({
    /**
     * Simple wrapper so `Dropdown3` can send child-specific click events to its own
     * parent without having to bind callbacks itself.
     */
    propTypes: {
        onClick: PropTypes.func,
        item: PropTypes.node.isRequired,
        disabled: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            disabled: false,
        }
    },

    render: function () {
        return <div onClick={this.handleClick}>{this.props.item}</div>
    },

    handleClick: function (event) {
        if (!this.props.disabled) {
            this.props.onClick(this.props.item, event)
        }
    },
})

export var Dropdown3ListItem = CreateReactClass({
    propTypes: {
        // Used by the `DropdownList3` parent (optional).
        identifier: PropTypes.any,
        disabled: PropTypes.bool,
    },

    getDefaultProps: function () {
        return {
            disabled: false,
        }
    },

    getInitialState: function () {
        return {
            isHovered: false,
        }
    },

    render: function () {
        let {
            children,
            style,
            disabled,
            onClick: _onClick,
            ...props
        } = this.props

        return (
            <div
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                style={{
                    backgroundColor: this.state.isHovered ? '#eaeaea' : null,
                    color: disabled ? '#aaa' : null,
                    cursor: 'pointer',
                    padding: '0.5em 1em',
                    ...style,
                }}
                onClick={this.handleClick}
                {...props}
            >
                {children}
            </div>
        )
    },

    handleClick: function () {
        if (!this.props.disabled && this.props.onClick != null) {
            this.props.onClick()
        }
    },

    handleMouseOver: function () {
        this.setState({ isHovered: true })
    },

    handleMouseOut: function () {
        this.setState({ isHovered: false })
    },
})
