import _ from 'underscore'
import { makeObservable, observable, computed, action } from 'mobx'
import SessionStore from '../../State/SessionStore'

const settingDefaults = {
    sortPhasesBy: 'startDate',
    allowNoPhase: true,
    useTasks: true,
    allowAfterPhaseEnd: true,
    timeEntryAllocations: ['noAllocations', 'budgets', 'allocations'],
    timeEntryStatus: ['active', 'prospective', 'onHold', 'archived'],
    timeEntryFlags: ['billable', 'variation', 'overtime'],
    autoPopulate: [], // ["budgets", "allocations"]
    updateHoursFromRevenue: false,
    updateRevenueFromHours: false,
    autoUpdateRevenue: {
        action: 'ask', // "automatic", "never"
        adjustOnLoad: false,
        budget: 'remaining',
        start: 'now',
        end: 'endDate',
    },
    autoUpdateHours: {
        action: 'ask', // "automatic", "never"
        adjustOnLoad: false,
        budget: 'remaining',
        start: 'now',
        end: 'endDate',
    },
    reportInvoiceDateType: 'issueDate',
    savingInvoices: ['markTimeInvoiced'], // "lockTime", "automatic"
}

class SettingsPageStore {
    @observable settings = {}
    constructor() {
        makeObservable(this)
        this.settings = {
            ...settingDefaults,
            ...(SessionStore.organisation?.settings || {}),
        }
    }

    @action.bound
    changeSetting(name, value) {
        this.settings[name] = value
        SessionStore.organisation.update({ settings: this.settings })
    }
}

export default SettingsPageStore
