import {
    observable,
    computed,
    action,
    makeObservable,
    runInAction,
    toJS,
} from 'mobx'
import React from 'react'
import _ from 'lodash'
import {
    addDays,
    addMonths,
    endOfMonth,
    format,
    startOfMonth,
    subMonths,
} from 'date-fns'
import RevenueForecastReportCollection from '../../State/Collections/RevenueForecastReportCollection'
import LayoutStore from '../../State/LayoutStore'
import SessionStore from '../../State/SessionStore'

import download from 'downloadjs'
import Papa from 'papaparse'
import bind from 'bind-decorator'
import fetchData from '../../Queries/fetchData'
import { qf } from '../../Queries/queryFormatter'
import FetchStore from '../../Queries/FetchStore'
import OverheadExpenseCollection from '../../State/Collections/OverheadExpenseCollection'
import TableStore from '../../Components/TableStore'
import { RevenueForecastReportPhaseColumns } from '../../reports/RevenueForecast/RevenueForecastReportPhaseColumns'
import { RevenueForecastReportStaffColumns } from '../../reports/RevenueForecast/RevenueForecastReportStaffColumns'
import { RevenueForecastReportOverheadColumns } from '../../reports/RevenueForecast/RevenueForecastReportOverheadColumns'
import { computedFn } from 'mobx-utils'
import { makeRequest } from '../../Queries/makeRequest'
import { router } from '../../App'

class RevenueForecastStore {
    @observable startDate = startOfMonth(subMonths(new Date(), 3))
    @observable endDate = endOfMonth(addMonths(new Date(), 8))
    @observable selectedObject = null
    @observable selectedMonth = null
    @observable selectedProjectId = null
    @observable selectedPhaseId = null
    @observable isPrinting = false
    @observable expandedRevenueRows = new Set()
    @observable queryData = []
    @observable editedRevenue = {}
    @observable projectTableStore = null
    @observable searchParams = {}

    constructor() {
        makeObservable(this)
        this.projectTableStore = new TableStore()
        this.staffTableStore = new TableStore()
        this.expenseTableStore = new TableStore()
    }

    @action.bound
    init(queryData) {
        this.queryData = queryData
        this.reset()
    }

    @action
    reset() {
        LayoutStore.toggleSidebar(false)
        this.startDate = startOfMonth(subMonths(new Date(), 3))
        this.endDate = endOfMonth(addMonths(new Date(), 8))
        this.selectedObject = null
        this.selectedMonth = null
        this.expandedRevenueRows = new Set()
        this.editedRevenue = {}
    }
    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }

    @action
    expandRevenueRow(row) {
        this.expandedRevenueRows.add(row)
    }

    @action
    collapseRevenueRow(row) {
        this.expandedRevenueRows.delete(row)
    }

    @computed
    get report() {
        return (
            RevenueForecastReportCollection.revenueForecastReportsById[
                this.searchParams?.report
            ] ||
            SessionStore.organisation.defaultRevenueForecastReport ||
            {}
        )
    }
    @action.bound
    updateFilters(filterData) {
        this.report.updateFilters(filterData)
        this.expandedRevenueRows = new Set()
    }

    @computed
    get csvLabelColumns() {
        return [
            {
                id: 'csvlabel',
                label: 'Title',
                type: 'text',
                width: 18,
                visible: false,
                editable: (row) => false,
                value: (row, stores) => {
                    if (stores.row.group) {
                        return stores.row.cells[stores.row.group].formattedValue
                    }
                    if (row.modelType === 'overheadExpense') return row.name
                    if (row.modelType === 'staff') return row.fullName
                    if (row.modelType === 'phase')
                        return `${row.project.title}: ${row.title}`
                    if (row.modelType === 'project') return row.title
                },
            },
        ]
    }

    @action.bound
    selectCell(selectedMonth, selectedObject, keyString = '') {
        const keys = keyString.split(',')
        //only show sidebar if it's the first cell selected in session
        if (
            !this.selectedMonth &&
            !this.selectedObject &&
            selectedMonth &&
            selectedObject &&
            !LayoutStore.showSidebar
        ) {
            LayoutStore.toggleSidebar(true)
        }
        this.selectedMonth = selectedMonth
        this.selectedObject = selectedObject
        this.selectedProjectId = keys[1]
        this.selectedPhaseId = keys[2]
    }

    @action.bound
    shiftSelectedPeriod(amount) {
        this.selectedMonth = addMonths(this.selectedMonth, amount)
        if (
            this.selectedMonth < this.startDate ||
            this.selectedMonth > this.endDate
        ) {
            this.shiftDates(amount)
        }
    }

    @action.bound
    shiftDates(amount) {
        this.startDate = addMonths(this.startDate, amount)
        this.endDate = addMonths(this.endDate, amount)
        let newDates = []
        if (amount > 0) {
            newDates = [startOfMonth(this.endDate), endOfMonth(this.endDate)]
        } else {
            newDates = [
                startOfMonth(this.startDate),
                endOfMonth(this.startDate),
            ]
        }
        this.updateRows()
    }

    @bind
    downloadCSV() {
        const revPhaseColumns = RevenueForecastReportPhaseColumns(this)
        const revRows = this.getRevenueRows('')
            .flatMap((r) => r.children)
            .flatMap((r) =>
                r.children.map((c) => {
                    return {
                        ...c,
                        title: `${r.title}: ${c.title}`,
                    }
                })
            )
        const revColumns = [
            {
                id: 'title',
                label: 'Title',
                type: 'text',
                value: (row, stores) => {
                    return row.title
                },
            },
            {
                id: 'costCentre',
                label: 'Cost Centre',
                type: 'text',
                value: (row, stores) => {
                    return row?.costCentre || 'Projects'
                },
            },
            ...[...Array(12)].map((v, i) => {
                return revPhaseColumns.revenue(
                    addMonths(this.startDate, i),
                    {},
                    {}
                )
            }),
        ]
        const revTableStore = new TableStore()
        revTableStore.update({
            columns: revColumns,
            rows: revRows,
        })
        const staffColumnDefs = RevenueForecastReportStaffColumns(this)
        const staffTableStore = new TableStore()
        const staffColumns = [
            staffColumnDefs.title(),
            {
                id: 'costCentre',
                label: 'Cost Centre',
                type: 'text',
                value: (row, stores) => {
                    return row.costCentre?.name || 'Staff'
                },
            },
            ...[...Array(12)].map((v, i) => {
                return staffColumnDefs.cost(addMonths(this.startDate, i), this)
            }),
        ]
        const staffRows = this.getStaffRows()
        staffTableStore.update({
            columns: staffColumns,
            rows: staffRows,
        })
        const overheadColumnDefs = RevenueForecastReportOverheadColumns(this)
        const overheadTableStore = new TableStore()
        const overheadColumns = [
            overheadColumnDefs.title(),
            {
                id: 'costCentre',
                label: 'Cost Centre',
                type: 'text',
                value: (row, stores) => {
                    return row.costCentre?.name || 'Expenses'
                },
            },
            ...[...Array(12)].map((v, i) => {
                return overheadColumnDefs.cost(
                    addMonths(this.startDate, i),
                    this
                )
            }),
        ]
        const overheadRows = this.getOverheadRows()
        overheadTableStore.update({
            columns: overheadColumns,
            rows: overheadRows,
            groupBy: ['title'],
        })
        download(
            Papa.unparse([
                ...revTableStore.getCsvData(),
                ...staffTableStore.getCsvData({
                    includeHeadings: false,
                }),
                ...overheadTableStore.getCsvData({
                    includeHeadings: false,
                }),
            ]),
            `${this.report.name}.csv`,
            'text/csv'
        )
    }

    @bind
    makeQueryKey(base) {
        return base + this.report.queryKey
    }

    @computed
    get fetchedProjectIds() {
        return this.queryData.flatMap((r) => r.children).map((r) => r.key)
    }

    getRevenueRows = computedFn((keyString = '') => {
        const keys = keyString ? keyString.split(',').filter((v) => v) : []
        let rows = this.queryData
        keys.forEach((k) => {
            rows = (
                rows.find((r) => r?.key === k)?.children || [
                    rows.find((r) => r?.key === k),
                ]
            ).filter((v) => v)
        })
        return rows || []
    })

    getAllLeafResourceRows = computedFn(() => {
        let rows = this.queryData
        while (rows[0]?.children) {
            rows = rows.flatMap((r) => r.children)
        }
        return rows
    })

    getLeafRevenueRows = computedFn((keyString = '') => {
        const rows = this.getAllLeafResourceRows()
        const keys = keyString?.split(',').filter((v) => v) || []
        return rows.filter((r) => keys.every((k) => r.fullPath.includes(k)))
    })

    getEditedRevenueData = computedFn((keyString = '') => {
        const rows = this.getLeafRevenueRows(keyString)
        return rows.flatMap((r) => this.editedRevenue[r.fullPath] || [])
    })

    @bind
    setEditedRevenueData(keyString = '', month, revenue, afterEdit) {
        const rows = this.getLeafRevenueRows(keyString).filter((r) => {
            return (
                r.status != undefined &&
                r.status !== 'archived' &&
                !r.isRootPhase &&
                r.isRootPhase != undefined
            )
        })
        const existingRevenue = this.getRevenueInMonth(keyString, month)
        const ratio = existingRevenue && revenue / existingRevenue
        rows.forEach((r) => {
            const rExistingRevenue = this.getRevenueInMonth(r.fullPath, month)
            const likelihood = this.getLikelihood(r.fullPath)
            let newRevenue =
                (ratio ? rExistingRevenue * ratio : revenue / rows.length) /
                likelihood
            newRevenue = isFinite(newRevenue) ? newRevenue : 0
            this.editedRevenue[r.fullPath] ??= []
            this.editedRevenue[r.fullPath] = this.editedRevenue[
                r.fullPath
            ].filter((h) => h.month !== month)
            this.editedRevenue[r.fullPath].push({
                fullPath: r.fullPath,
                month,
                revenue: newRevenue,
            })
            r.data = r.data.filter(
                (d) => !(d.month === month && d.type === 'revenueTarget')
            )

            if (afterEdit && this.selectedProjectId) {
                let ids = r.fullPath.split(',')
                afterEdit(month, newRevenue, ids[1], ids[2])
            }
        })
    }

    getRowRevenueData = computedFn((keyString = '') => {
        const rows = this.getLeafRevenueRows(keyString)
        return rows.flatMap((r) => r.data || [])
    })

    @computed
    get dataMonths() {
        return [
            ...new Set(
                [
                    ...this.getRowRevenueData(null),
                    ...this.getEditedRevenueData(null),
                ].map((d) => d.month)
            ),
        ].filter((v) => v)
    }

    getTotalFee = computedFn((keyString = '') => {
        const phases = this.getLeafRevenueRows(keyString)
        return _.sum(
            phases.map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? b.likelihood ?? 1 : 1
                return (b.fee || 0) * likelihood
            })
        )
    })

    getLikelihood = computedFn((keyString = '') => {
        const phases = this.getRowRevenueData(keyString).filter(
            (b) => b.type === 'phase'
        )
        return _.mean(
            phases.map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                return useLikelihood ? b.likelihood ?? 1 : 1
            })
        )
    })

    getEditedRevenueDataInMonth = computedFn((keyString = '', month) => {
        return this.getEditedRevenueData(keyString).filter(
            (d) => d.month === month
        )
    })

    getRevenueDataInMonth = computedFn((keyString = '', month) => {
        return this.getRowRevenueData(keyString).filter((d) => {
            return d.month === month
        })
    })

    getRevenueTargetDataInMonth = computedFn((keyString = '', month) => {
        return this.getRevenueDataInMonth(keyString, month).filter((d) => {
            return d.type === 'revenueTarget'
        })
    })

    getInvoiceDataInMonth = computedFn((keyString = '', month) => {
        return this.getRevenueDataInMonth(keyString, month).filter((d) => {
            return d.type === 'invoice'
        })
    })

    getChangeLogDataInMonth = computedFn((keyString = '', month) => {
        return this.getRevenueDataInMonth(keyString, month).filter((d) => {
            return d.type === 'changeLog'
        })
    })

    getExpenseDataInMonth = computedFn((keyString = '', month) => {
        return this.getRevenueDataInMonth(keyString, month).filter((d) => {
            return d.type === 'changeLog' || d.type === 'expenseAllocation'
        })
    })

    getProspectiveRevenueTargetDataInMonth = computedFn(
        (keyString = '', month) => {
            return this.getRevenueTargetDataInMonth(keyString, month).filter(
                (d) => {
                    return d.status === 'prospective'
                }
            )
        }
    )

    getProspectiveRevenueInMonth = computedFn((keyString = '', month) => {
        const thisMonth = format(new Date(), 'yyyy-MM')
        if (month < thisMonth) {
            return 0
        }
        return _.sum(
            this.getProspectiveRevenueTargetDataInMonth(keyString, month).map(
                (b) => {
                    const useLikelihood = ['prospective', 'onHold'].includes(
                        b.status
                    )
                    const likelihood = useLikelihood ? b.likelihood ?? 1 : 1
                    return b.revenue * likelihood
                }
            )
        )
    })

    getEditedRevenueInMonth = computedFn((keyString = '', month) => {
        return _.sum(
            this.getEditedRevenueDataInMonth(keyString, month).map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? b.likelihood ?? 1 : 1
                return b.revenue * likelihood
            })
        )
    })

    getRevenueTargetRevenueInMonth = computedFn((keyString = '', month) => {
        return _.sum(
            this.getRevenueTargetDataInMonth(keyString, month).map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? b.likelihood ?? 1 : 1
                return b.revenue * likelihood
            })
        )
    })

    getInvoiceRevenueInMonth = computedFn((keyString = '', month) => {
        return _.sum(
            this.getInvoiceDataInMonth(keyString, month).map((d) => d.revenue)
        )
    })

    getChangeLogRevenueInMonth = computedFn((keyString = '', month) => {
        return _.sum(
            this.getChangeLogDataInMonth(keyString, month).map((d) => d.revenue)
        )
    })

    getExpensesInMonth = computedFn((keyString = '', month) => {
        if (!this.report.filters.showExpenses) return 0
        return _.sum(
            this.getExpenseDataInMonth(keyString, month).map((d) => d.expenses)
        )
    })

    getRevenueInMonth = computedFn((keyString = '', month) => {
        const phases = this.getLeafRevenueRows(keyString)
        if (phases.length > 1) {
            return _.sum(
                phases.map((p) => this.getRevenueInMonth(p.fullPath, month))
            )
        }
        const revenueData = this.report.filters.revenueData
        const thisMonth = format(new Date(), 'yyyy-MM')
        const isPastMonth = month < thisMonth
        const isFutureMonth = month > thisMonth
        const isCurrentMonth = month === thisMonth
        if (revenueData === 'projected') {
            return (
                this.getRevenueTargetRevenueInMonth(keyString, month) +
                this.getEditedRevenueInMonth(keyString, month)
            )
        }
        if (revenueData === 'actuals') {
            return (
                this.getInvoiceRevenueInMonth(keyString, month) +
                this.getChangeLogRevenueInMonth(keyString, month) -
                this.getExpensesInMonth(keyString, month)
            )
        }
        if (isPastMonth) {
            return (
                this.getInvoiceRevenueInMonth(keyString, month) +
                this.getChangeLogRevenueInMonth(keyString, month) -
                this.getExpensesInMonth(keyString, month)
            )
        }
        if (isFutureMonth) {
            return (
                this.getRevenueTargetRevenueInMonth(keyString, month) +
                this.getEditedRevenueInMonth(keyString, month)
            )
        }
        if (isCurrentMonth) {
            return Math.max(
                this.getRevenueTargetRevenueInMonth(keyString, month) +
                    this.getEditedRevenueInMonth(keyString, month),
                this.getInvoiceRevenueInMonth(keyString, month) +
                    this.getChangeLogRevenueInMonth(keyString, month) -
                    this.getExpensesInMonth(keyString, month)
            )
        }
    })

    getRevenueToDateInMonth = computedFn((keyString = '', month) => {
        const months = this.dataMonths.filter((m) => m <= month)
        return _.sum(months.map((m) => this.getRevenueInMonth(keyString, m)))
    })

    getTotalRevenue = computedFn((keyString = '') => {
        return _.sum(
            this.dataMonths.map((m) => this.getRevenueInMonth(keyString, m))
        )
    })

    @bind
    getStaffRows() {
        let id = 'staff-cost'
        return FetchStore.getResponse(this.makeQueryKey(id))?.staff?.staff || []
    }

    @bind
    staffRowQuery() {
        let id = 'staff-cost'
        id = this.makeQueryKey(id)
        const reportFilters = this.report.filters
        const fields = [
            'firstName',
            'lastName',
            'staffType',
            'roleId',
            'costCentreId',
            'inheritPayRate',
            'inheritOvertimeRate',
            'inheritCostRate',
            'inheritChargeOutRate',
            ['latestDate', 'latestRate.latestDate'],
            ['latestAvailability', 'latestAvailability.weeklyAvailability'],
        ]
        const filters = [
            'isArchived == false or latestAvailability > 0',
            ...(reportFilters?.costCentres?.length
                ? [`costCentreId in ${qf(reportFilters.costCentres)}`]
                : []),
            ...(reportFilters?.staff?.length
                ? [`id in ${qf(reportFilters.staff)}`]
                : []),
        ]
        return {
            id,
            collection: 'staff',
            fields,
            filters,
            subQueries: [
                {
                    label: 'latestRate',
                    collection: 'staffRates',
                    join: 'id == latestRate.staffId',
                    fields: [['latestDate', 'max(date)']],
                    groupBy: ['staffId'],
                    filters: [`date <= ${qf(this.startDate)}`],
                },
                {
                    label: 'latestAvailability',
                    collection: 'staffRates',
                    join: 'latestDate == latestAvailability.date and id == latestAvailability.staffId',
                    fields: [['weeklyAvailability', 'max(weeklyAvailability)']],
                    groupBy: ['staffId', 'date'],
                },
            ],
            chain: [
                {
                    collection: 'staffRates',
                    join: {
                        staff: 'id',
                        staffRates: 'staffId',
                    },
                    fields: [
                        'staffId',
                        'date',
                        'payRate',
                        'chargeOutRate',
                        'costRate',
                        'weeklyAvailability',
                        'overtimeRate',
                    ],
                    filters: [`date <= ${qf(this.endDate)}`],
                },
                {
                    collection: 'roleRates',
                    join: {
                        staff: 'roleId',
                        roleRates: 'roleId',
                    },
                    fields: [
                        'roleId',
                        'date',
                        'payRate',
                        'chargeOutRate',
                        'costRate',
                        'overtimeRate',
                    ],
                    filters: [`date <= ${qf(this.endDate)}`],
                },
            ],
        }
    }
    @bind
    getOverheadRows() {
        let id = 'overhead-cost'
        return _.uniq(
            FetchStore.getResponse(this.makeQueryKey(id))?.overheadExpenses
                ?.overheadExpenses || []
        ).map((e) => e.name)
    }

    @bind
    overheadRowQuery() {
        let id = 'overhead-cost'
        id = this.makeQueryKey(id)
        const reportFilters = this.report.filters
        const fields = [
            'name',
            'costCentreId',
            'value',
            'startDate',
            'endDate',
            'hasRepeat',
            'repeatQuantity',
            'repeatUnit',
        ]
        const filters = [
            `endDate == null or endDate >= ${qf(this.startDate)}`,
            `startDate == null or startDate <= ${qf(this.endDate)}`,
            'startDate != null or endDate != null',
            `(hasRepeat == false or (hasRepeat == true and repeatQuantity > 0))`,
            'value != null and value != 0',
            ...(reportFilters?.costCentres?.length
                ? [`costCentreId in ${qf(reportFilters.costCentres)}`]
                : []),
            ...(reportFilters?.expenses
                ?.map(
                    (e) =>
                        OverheadExpenseCollection.overheadExpensesById[e]?.name
                )
                ?.filter(Boolean)?.length
                ? [
                      `name in ${qf(
                          reportFilters.expenses.map(
                              (e) =>
                                  OverheadExpenseCollection
                                      .overheadExpensesById[e]?.name
                          )
                      )}`,
                  ].filter(Boolean)
                : []),
        ]
        return {
            id,
            collection: 'overheadExpenses',
            fields,
            filters,
        }
    }

    @action.bound updateRows = _.debounce(() => {
        fetchData(this.staffRowQuery())
        fetchData(this.overheadRowQuery())
        makeRequest({
            id: `revenueTable` + this.report.queryKey,
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: `/revenue-forecast/table`,
            method: 'POST',
            data: {
                organisationId: SessionStore.organisationId,
                userId: SessionStore.user?.id,
                filters: this.report.filters,
                level: 0,
                parentData: {},
                dateRange: [
                    format(this.startDate, 'yyyy-MM-dd'),
                    format(this.endDate, 'yyyy-MM-dd'),
                ],
                existingProjectIds: this.fetchedProjectIds,
            },
        }).then((r) => {
            this.updateQueryData(r.data)
        })
    }, 500)

    @action.bound
    updateQueryData(data) {
        const queryData = this.queryData
        const statusRows = data
        statusRows.forEach((r) => {
            let statusRow = queryData.find((q) => q.key === r.key)
            if (!statusRow) {
                queryData.push(r)
            }
            r.children.forEach((c) => {
                let childRow = statusRow?.children.find((q) => q.key === c.key)
                if (!childRow) {
                    statusRow?.children.push(c)
                }
            })
        })
        requestIdleCallback(() => {
            this.queryData = queryData
        })
    }
}

export default new RevenueForecastStore()
