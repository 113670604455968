import { pascalCase } from 'change-case'
import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import getRateAtDate from '../../Utils/getRateAtDate'
import getRateInDateRange from '../../Utils/getRateInDateRange'
import CostCentreCollection from '../Collections/CostCentreCollection'
import DailyAllocationCollection from '../Collections/DailyAllocationCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import RoleCollection from '../Collections/RoleCollection'
import StaffCollection from '../Collections/StaffCollection'
import StaffRateCollection from '../Collections/StaffRateCollection'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import Model from './Model'
import BudgetCollection from '../Collections/BudgetCollection'
import RoleRateCollection from '../Collections/RoleRateCollection'
import { permissionPresets } from '../Permissions/PermissionPresets'
import AllocationCollection from '../Collections/AllocationCollection'
import {
    canSeeOperationalExpenses,
    canSeeSalaries,
    canViewBilling,
    canViewSettings,
} from '../Permissions/HasPermissions'
import bind from 'bind-decorator'
import cuid from 'cuid'
import { differenceInBusinessDays } from 'date-fns'

class StaffModel extends Model {
    @observable firstName = null
    @observable lastName = null
    @observable email = null
    @observable roleId = null
    @observable isArchived = null
    @observable isDeleted = null
    @observable inheritPayRate = null
    @observable inheritOvertimeRate = null
    @observable inheritCostRate = null
    @observable inheritChargeOutRate = null
    @observable country = null
    @observable permissions = null
    @observable billingLevelOverride = null
    @observable costCentreId = null
    @observable staffType = null
    @observable hasLogin = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = StaffCollection
        this.init(data, options)
    }

    update(data, options) {
        if (data?.permissions?.projects) {
            data.permissions.projects = data.permissions.projects.map((p) => ({
                ...p,
                id: p.id || p.uuid || cuid(),
            }))
        }
        super.update(data, options)
    }
    @computed
    get role() {
        return RoleCollection.modelsById[this.roleId]
    }
    @computed
    get costCentre() {
        return CostCentreCollection.modelsById[this.costCentreId]
    }

    @computed
    get allocations() {
        return AllocationCollection.allocationsByStaffId[this.id] || []
    }

    @computed
    get dailyAllocations() {
        return (
            DailyAllocationCollection.dailyAllocationsByStaffId[this.id] || []
        )
    }

    @computed
    get ownerOf() {
        return ProjectCollection.projectsByOwnerId[this.id]
    }
    @computed
    get rates() {
        return StaffRateCollection.ratesByStaffId[this.id] || []
    }
    @computed
    get payRate() {
        return this.getRateAtDate('pay', new Date())
    }
    @computed
    get overtimeRate() {
        return this.getRateAtDate('overtime', new Date())
    }
    @computed
    get costRate() {
        return this.getRateAtDate('cost', new Date())
    }
    @computed
    get chargeOutRate() {
        return this.getRateAtDate('chargeOut', new Date())
    }
    @computed
    get availability() {
        return this.getAvailabilityAtDate(new Date())
    }
    @computed
    get minRateDate() {
        return _.min(this.rates.map((r) => r.date))
    }
    @computed
    get staffAndRoleRates() {
        return [...this.rates, ...(this.role?.rates || [])]
    }
    @computed
    get timeEntries() {
        return TimeEntryCollection.timeEntriesByStaffId[this.id]
    }

    @computed
    get fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    @computed
    get budgets() {
        return BudgetCollection.budgetsByStaffId[this.id] || []
    }

    @bind
    inheritRateType(rateType) {
        return this['inherit' + pascalCase(rateType) + 'Rate']
    }

    @bind
    getRateInDateRange(rateType, dateRange) {
        if (this.role && this.inheritRateType(rateType)) {
            return this.role.getRateInDateRange(rateType, dateRange)
        }
        return getRateInDateRange(this.rates, rateType, dateRange)
    }

    @bind
    getRateAtDate(rateType, date) {
        if (this.role && this.inheritRateType(rateType)) {
            return this.role.getRateAtDate(rateType, date)
        }
        return getRateAtDate(this.rates, rateType, date)
    }

    @bind
    getAvailabilityInDateRange(dateRange) {
        return getRateInDateRange(this.rates, 'availability', dateRange)
    }

    @bind
    getAvailabilityAtDate(date) {
        return getRateAtDate(this.rates, 'availability', date)
    }

    @bind
    getAvailableHoursInDateRange(dateRange) {
        const dailyAvailableHours =
            this.getAvailabilityInDateRange(dateRange) / 60 / 5
        const weekdaysInDateRange = differenceInBusinessDays(
            dateRange[1],
            dateRange[0]
        )
        //TODO holidays
        return dailyAvailableHours * weekdaysInDateRange
    }

    @bind
    hoursInDateRange(dateRange) {
        return _.sum(
            this.allocations
                .filter((a) => a.date >= dateRange[0] && a.date <= dateRange[1])
                .map((a) => a.hours)
        )
    }

    @computed
    get billingLevel() {
        if (this.billingLevelOverride) {
            return this.billingLevelOverride
        }
        if (
            !this.hasLogin ||
            this.isArchived ||
            this.deletedAt ||
            !this.email ||
            this.email === ''
        ) {
            return 'none'
        }
        if (
            canSeeSalaries(this) ||
            canSeeOperationalExpenses(this) ||
            canViewBilling(this) ||
            canViewSettings(this)
        ) {
            return 'admin'
        }
        return 'standard'
    }
}

export default StaffModel
