import { addDays, differenceInDays, format } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Selector } from '../../Components/Selector'
import Table from '../../Components/Table'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import LayoutStore from '../../State/LayoutStore'
import { Dropdown3, Dropdown3ListItem } from '../../widgets'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'
import InvoiceLineItemCollection from '../../State/Collections/InvoiceLineItemCollection'
import Inputs from '../../Components/Inputs'
import CellComponent from '../../Components/CellComponent'
import AddTimesheetModal from './AddTimesheetModal'
import AddExpenseModal from './AddExpenseModal'
import ChangeDateRangeModal from './ChangeDateRangeModal'
import SessionStore from '../../State/SessionStore'
import RenderOnQueries from '../Layout/RenderOnQueries'
import BilledTimeModal from './BilledTimeModal'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import invoiceUrls from './invoiceUrls'
import InvoiceDescriptionTemplateCollection from '../../State/Collections/InvoiceDescriptionTemplateCollection'
import InvoiceDescriptionTemplateModal from '../InvoiceListPage/InvoiceDescriptionTemplateModal'
import { InvoiceCodeInfo } from '../../invoices/invoiceCodeInfo'
import { qf } from '../../Queries/queryFormatter'
import { canEditProjectInvoices } from '../../State/Permissions/HasPermissions'
import { capitalCase } from 'change-case'
import sortPhases from '../../Utils/sortPhases'
import FetchStore from '../../Queries/FetchStore'
import { useNavigate } from '@tanstack/react-router'

const invoiceColumns = [
    {
        id: 'ref',
        label: 'Invoice Number',
        width: 35,
        type: 'text',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.ref,
        onChange: (r) => (v) => r.update({ ref: v }),
    },
    {
        id: 'contact',
        label: 'Addressed To',
        width: 22,
        type: 'contact',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.contact,
        onChange: (r) => (v) => r.update({ contactId: v.id }),
    },
    {
        id: 'issueDate',
        label: 'Issue Date',
        width: 15,
        type: 'date',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.issueDate,
        onChange: (r) => (v) => {
            const dueIn = differenceInDays(r.dueDate, r.issueDate)
            r.update({
                issueDate: v,
                dueDate: addDays(v, dueIn),
            })
        },
    },
    {
        id: 'dueIn',
        label: 'Due Date',
        width: 8,
        type: 'number',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => differenceInDays(r.dueDate, r.issueDate),
        onChange: (r) => (v) => r.update({ dueDate: addDays(r.issueDate, v) }),
    },
    {
        id: 'dueInDays',
        label: '',
        width: 8,
        type: 'text',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => 'days',
    },
    {
        id: 'dueDate',
        label: 'Due Date',
        width: 16,
        type: 'date',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.dueDate,
        onChange: (r) => (v) => r.update({ dueDate: v }),
    },
    {
        id: 'taxRate',
        label: 'Tax Rate',
        width: 16,
        type: 'percent',
        editable: (r) =>
            canEditProjectInvoices(SessionStore.user, r.project) &&
            (SessionStore.organisation.accountingSystem === 'none' ||
                SessionStore.organisation.accountingSystem == null),
        onChange: (r) => (v) => r.update({ taxRatePercent: v * 100 }),
        value: (r) => r.taxRatePercent / 100,
    },
]

const lineItemColumns = [
    {
        id: 'type',
        label: 'Type',
        width: 15,
        type: 'dropdown',
        editable: (r) =>
            r.lineItemType !== 'note' &&
            canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.billingType,
        onChange: (r) => (v) => r.update({ billingType: v }),
        component: ({ stores, value, onChange, editable }) => {
            const lineItemType = stores.row.rowObject.lineItemType
            if (!editable)
                return (
                    <div style={{ padding: '0.5em' }}>
                        {lineItemType === 'note' ? 'Note' : capitalCase(value)}
                    </div>
                )
            switch (lineItemType) {
                case 'note':
                    return <div style={{ padding: '0.5em' }}>Note</div>
                default:
                    return (
                        <Selector
                            selectedOption={value}
                            options={[
                                'agreedFee',
                                'variation',
                                'reimbursement',
                            ]}
                            optionLabel={(opt) => {
                                if (opt === 'agreedFee') return 'Agreed Fee'
                                if (opt === 'variation') return 'Variation'
                                if (opt === 'reimbursement')
                                    return 'Reimbursement'
                            }}
                            onChange={onChange}
                            variant="secondary"
                            className="!border-none"
                        />
                    )
            }
        },
    },
    {
        id: 'description',
        label: (
            <span>
                Description
                <InvoiceCodeInfo style={{ marginLeft: '0.5em' }} />
            </span>
        ),
        width: 50,
        type: 'textarea',
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.description,
        onChange: (r) => (v) => r.update({ description: v }),
        component: (props) => {
            const li = props.stores.row.rowObject
            return <LineItemDescription {...props} lineItem={li} />
        },
    },
    {
        id: 'qty',
        label: 'QTY',
        width: 11,
        type: 'number',
        editable: (r) =>
            r.lineItemType !== 'note' &&
            canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.unitQuantity,
        style: (r, stores) => {
            return r?.unitQuantity < 0
                ? { color: 'red', outline: 'solid red 2px' }
                : {}
        },
        onChange: (r) => (v) => {
            if (
                !v &&
                r.billingType === 'agreedFee' &&
                ['progress', 'projectProgress', 'fixed'].includes(
                    r.lineItemType
                )
            ) {
                r.timeEntries.forEach((te) =>
                    te.update({ beenInvoiced: false })
                )
                r.update({
                    timesheetIds: [],
                })
            } else if (
                v &&
                r.billingType === 'agreedFee' &&
                ['progress', 'projectProgress', 'fixed'].includes(
                    r.lineItemType
                )
            ) {
                const timeEntries = TimeEntryCollection.timeEntries.filter(
                    (te) => {
                        return (
                            (te.hours || te.notes) &&
                            te.isBillable &&
                            !te.isVariation &&
                            !te.beenInvoiced &&
                            !te.deletedAt &&
                            te.staff &&
                            te.project === r.project &&
                            te.phase == r.phase &&
                            te.date <= r.invoice.endDate &&
                            te.date >= r.invoice.startDate
                        )
                    }
                )
                r.update({
                    timesheetIds: timeEntries.map((te) => te.id),
                })
                timeEntries.forEach((te) => te.update({ beenInvoiced: true }))
            }
            r.update({ unitQuantity: v })
        },
        component: (props) => {
            const lineItemType = props.stores.row.rowObject.lineItemType
            switch (lineItemType) {
                case 'progress':
                case 'projectProgress':
                    return CellComponent.percent(props)
                case 'note':
                    return <div></div>
                default:
                    return CellComponent.number(props)
            }
        },
    },
    {
        id: 'unitCost',
        label: 'Unit cost',
        width: 12,
        type: 'currency',
        editable: (r) =>
            r.lineItemType !== 'note' &&
            canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.unitCost,
        onChange: (r) => (v) => r.update({ unitCost: v }),
        component: (props) => {
            const lineItemType = props.stores.row.rowObject.lineItemType
            switch (lineItemType) {
                case 'note':
                    return <div></div>
                default:
                    return CellComponent.currency(props)
            }
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        width: 12,
        type: 'currency',
        editable: (r) => false,
        value: (r) => r.amount,
        component: (props) => {
            const lineItemType = props.stores.row.rowObject.lineItemType
            switch (lineItemType) {
                case 'note':
                    return <div></div>
                default:
                    return CellComponent.currency(props)
            }
        },
    },
    {
        id: 'tax',
        label: 'Tax',
        width: 12,
        type: 'currency',
        editable: (r) => false,
        value: (r) => (r.lineItemType !== 'note' ? r.tax : null),
        component: (props) => {
            const lineItemType = props.stores.row.rowObject.lineItemType
            switch (lineItemType) {
                case 'note':
                    return <div></div>
                default:
                    return CellComponent.currency(props)
            }
        },
    },
    {
        id: 'isTaxed',
        label: '',
        width: 4,
        type: 'checkbox',
        permissions: (r) =>
            canEditProjectInvoices(SessionStore.user, r.project),
        editable: (r) =>
            r.lineItemType !== 'note' &&
            canEditProjectInvoices(SessionStore.user, r.project),
        value: (r) => r.isTaxed,
        onChange: (r) => (v) => {
            r.update({ isTaxed: v })
        },
        component: (props) => {
            if (!props.editable) return <div />
            const lineItemType = props.stores.row.rowObject.lineItemType
            switch (lineItemType) {
                case 'note':
                    return <div></div>
                default:
                    return CellComponent.checkbox(props)
            }
        },
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) =>
            canEditProjectInvoices(SessionStore.user, r.project),
        editable: (r) => canEditProjectInvoices(SessionStore.user, r.project),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.timeEntries.forEach((te) => te.update({ beenInvoiced: false }))
            r.update({ deletedAt: new Date() })
        },
    },
    {
        id: 'createdAt',
        type: 'date',
        visible: false,
        value: (r) => r.createdAt || r.initiatedAt,
    },
    {
        id: 'timeEntryStartDate',
        type: 'date',
        visible: false,
        value: (r) => r.timeEntryStartDate,
    },
]

export default ({ id }) => {
    const navigate = useNavigate()
    const isAtLeastPM = true
    const isAtLeastAdmin = true
    const invoice =
        InvoiceCollection.invoicesById[id] ||
        InvoiceCollection.invoicesByOldId[id]
    if (!invoice) return null
    if (id === String(invoice.oldId)) {
        navigate({
            to: '/invoices/$id',
            params: { id: invoice.id },
        })
    }
    const invoiceExternalLink = invoiceUrls[invoice.accountingSystemId]
    return (
        <RenderOnQueries
            key={invoice.id}
            queryIds={[
                {
                    id: 'te' + invoice.id,
                    collection: 'timeEntries',
                    fields: [
                        'projectId',
                        'numMinutes',
                        ['chargeOut', 'chargeOut::numeric/100'],
                        'phaseId',
                        'staffId',
                        'date',
                        'isVariation',
                        'isBillable',
                        'beenInvoiced',
                        'taskId',
                        'notes',
                    ],
                    filters: [
                        `projectId == "${invoice.project.id}"`,
                        `date >= ${qf(invoice.startDate)}`,
                        `date <= ${qf(invoice.endDate)}`,
                    ],
                    staleTime: 0,
                },
                {
                    id: 'ph' + invoice.id,
                    collection: 'phases',
                    fields: [
                        'jobNumber',
                        'name',
                        'projectId',
                        'startDate',
                        'fee',
                        'isRootPhase',
                        ['previousBilled', `phaseLineItems.previousAmount`],
                    ],
                    filters: [`projectId == "${invoice.project.id}"`],
                    subQueries: [
                        {
                            label: 'phaseLineItems',
                            collection: 'invoiceLineItems',
                            join: 'id == phaseLineItems.phaseId',
                            groupBy: ['phaseId'],
                            fields: [
                                [
                                    'previousAmount',
                                    'sum(billingType == "agreedFee" ? amount : 0)',
                                ],
                            ],
                            filters: [
                                "billingType == 'agreedFee'",
                                `invoice.issueDate < ${qf(invoice.issueDate)}`,
                            ],
                        },
                    ],
                    staleTime: 0,
                },
                {
                    id: 'pe' + invoice.id,
                    collection: 'projectExpenses',
                    fields: [
                        'name',
                        'projectId',
                        'phaseId',
                        ['previousBilled', `expenseLineItems.previousAmount`],
                    ],
                    filters: [`projectId == "${invoice.project.id}"`],
                    subQueries: [
                        {
                            label: 'expenseLineItems',
                            collection: 'invoiceLineItems',
                            join: 'id == expenseLineItems.expenseId',
                            groupBy: ['expenseId'],
                            fields: [['previousAmount', 'sum(amount)']],
                            filters: [
                                `invoice.issueDate < ${qf(invoice.issueDate)}`,
                            ],
                        },
                    ],
                    staleTime: 0,
                },
            ]}
        >
            <InvoicePage
                invoice={invoice}
                invoiceExternalLink={invoiceExternalLink}
            />
        </RenderOnQueries>
    )
}

const InvoicePage = observer(({ invoice, invoiceExternalLink }) => {
    useEffect(() => {
        if (!invoice.cachedData) {
            invoice.updateCachedData()
        }
    }, [invoice])
    if (!invoice.cachedData) return null
    return (
        <div key={invoice.id}>
            <div className="flex items-center justify-between p-[1em] border-b border-[#e5e5e5]">
                <div>
                    <h3
                        style={{
                            marginTop: 0,
                            fontSize: '2em',
                            marginBottom: 0,
                        }}
                    >
                        {invoice.project.title}
                    </h3>
                    <div>
                        <span>{'For work carried out from '}</span>
                        <strong>
                            {format(invoice.startDate, 'dd/MM/yyyy')}
                        </strong>
                        {' to '}
                        <strong>{format(invoice.endDate, 'dd/MM/yyyy')}</strong>
                        {canEditProjectInvoices(
                            SessionStore.user,
                            invoice.pr
                        ) ? (
                            <button
                                className="btn btn-sm btn-tiny"
                                onClick={() => {
                                    return LayoutStore.openModal(
                                        <ChangeDateRangeModal
                                            modalId="changeDateRange"
                                            invoice={invoice}
                                        />
                                    )
                                }}
                                style={{ marginLeft: '1em' }}
                            >
                                Change...
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="flex-1-1-auto" />
                <div className="flex-0-0-auto" style={{ textAlign: 'right' }}>
                    <div
                        style={{
                            fontStyle: 'italic',
                            fontSize: '0.9em',
                        }}
                    >
                        Created:{' '}
                        {format(
                            invoice.createdAt || new Date(),
                            'EEE dd MMMM, yyyy'
                        )}
                    </div>

                    {invoiceExternalLink != null ? (
                        <a
                            target="_blank"
                            href={invoiceExternalLink.getUrl(
                                invoice.accountingSystemInvoiceId
                            )}
                        >
                            <button
                                className="btn btn-sm btn-xero"
                                style={invoiceExternalLink.style}
                            >
                                View in {invoiceExternalLink.label}
                            </button>
                        </a>
                    ) : null}
                </div>
            </div>
            <div
                style={{
                    padding: '1em 0',
                    borderBottom: 'solid 1px #e5e5e5',
                    fontSize: '1.05em',
                    marginBottom: '4em',
                }}
            >
                <Table
                    columns={invoiceColumns}
                    rows={[invoice]}
                    fullWidth={true}
                />
                {InvoiceCollection.invoices.filter(
                    (i) => i != invoice && i.ref === invoice.ref
                ).length ? (
                    <div
                        style={{
                            color: 'red',
                            padding: '1em 0 0 1em',
                            fontStyle: 'italic',
                            fontSize: '1.25rem',
                        }}
                    >
                        Invoice Number Already In Use
                    </div>
                ) : null}
            </div>

            {invoice.phases?.sort(sortPhases).map((ph) => (
                <PhaseLineItems key={ph.id} ph={ph} invoice={invoice} />
            ))}
            <div
                style={{
                    borderTop: 'solid 1px #aaa',
                    paddingTop: '1.5em',
                }}
            >
                <div style={{ textAlign: 'right' }}>
                    <div
                        style={{
                            display: 'inline-block',
                            textAlign: 'right',
                            margin: '1em',
                            width: '30%',
                        }}
                    >
                        <div className="flex items-center justify-between">
                            <div
                                className="flex-0-0-auto"
                                style={{ width: '35%' }}
                            >
                                Subtotal
                            </div>
                            <div
                                className="flex-1-1-auto invoice__total-ex-tax"
                                style={{ marginRight: '6%' }}
                            >
                                {FormatCurrency(invoice.amount)}
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div
                                className="flex-0-0-auto"
                                style={{ width: '35%' }}
                            >
                                Tax (
                                {FormatPercent(invoice.taxRatePercent / 100, {
                                    decimals: 0,
                                })}
                                )
                            </div>
                            <div
                                className="flex-1-1-auto"
                                style={{ marginRight: '6%' }}
                            >
                                {FormatCurrency(invoice.tax)}
                            </div>
                        </div>
                        <div
                            className="flex items-center justify-between"
                            style={{
                                fontSize: '1.3em',
                                fontWeight: 600,
                                marginTop: '1em',
                                paddingTop: '0.5em',
                                borderTop: '2px solid #888',
                            }}
                        >
                            <div
                                className="flex-0-0-auto"
                                style={{ width: '35%' }}
                            >
                                Total
                            </div>
                            <div
                                className="invoice__total-inc-tax flex-1-1-auto"
                                style={{ marginRight: '6%' }}
                            >
                                {FormatCurrency(invoice.amount + invoice.tax)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const PhaseLineItems = observer(({ ph, invoice }) => {
    const hasProgressLine = invoice.cachedData.phases[ph.id]?.fee > 0
    const prevPercent =
        invoice.cachedData.phases[ph.id]?.previousBilled /
        invoice.cachedData.phases[ph.id]?.fee
    const currentPercent =
        (invoice.cachedData.phases[ph.id]?.previousBilled +
            _.sum(
                invoice.lineItems
                    .filter(
                        (li) =>
                            li.billingType === 'agreedFee' && li.phase === ph
                    )
                    .map((li) => li.amount)
            )) /
        invoice.cachedData.phases[ph.id]?.fee

    const [lineItems, setLineItems] = useState(
        [
            ...(invoice.lineItemsByPhaseId[ph.id] || []),
            ...(ph.isRootPhase
                ? [
                      ...(invoice.lineItemsByPhaseId[null] || []),
                      ...(invoice.lineItemsByPhaseId[undefined] || []),
                      ...(invoice.lineItemsByPhaseId[-1] || []),
                  ]
                : []),
        ].sort((a, b) => {
            if (a.createdAt !== b.createdAt) {
                return b.createdAt - a.createdAt
            } else if (a.initiatedAt !== b.initiatedAt) {
                return b.initiatedAt - a.initiatedAt
            } else {
                // Sorting by the minimum date in timeEntries if present
                if (a.timeEntries.length > 0 && b.timeEntries.length > 0) {
                    const minDateA = Math.min(
                        ...a.timeEntries.map((t) => new Date(t.date).getTime())
                    )
                    const minDateB = Math.min(
                        ...b.timeEntries.map((t) => new Date(t.date).getTime())
                    )
                    if (minDateA !== minDateB) {
                        return minDateA - minDateB
                    }
                } else if (a.timeEntries.length > 0) {
                    return -1 // a has timeEntries but b doesn't, so a comes first
                } else if (b.timeEntries.length > 0) {
                    return 1 // b has timeEntries but a doesn't, so b comes first
                }

                // Fallback to description comparison
                return (a.description || '').localeCompare(b.description || '')
            }
        })
    )

    useEffect(() => {
        setLineItems([
            ...[
                ...(invoice.lineItemsByPhaseId[ph.id] || []),
                ...(ph.isRootPhase
                    ? [
                          ...(invoice.lineItemsByPhaseId[null] || []),
                          ...(invoice.lineItemsByPhaseId[undefined] || []),
                          ...(invoice.lineItemsByPhaseId[-1] || []),
                      ]
                    : []),
            ].sort((a, b) => {
                if (a.createdAt !== b.createdAt) {
                    return b.createdAt - a.createdAt
                } else if (a.initiatedAt !== b.initiatedAt) {
                    return b.initiatedAt - a.initiatedAt
                } else {
                    // Sorting by the minimum date in timeEntries if present
                    if (a.timeEntries.length > 0 && b.timeEntries.length > 0) {
                        const minDateA = Math.min(
                            ...a.timeEntries.map((t) =>
                                new Date(t.date).getTime()
                            )
                        )
                        const minDateB = Math.min(
                            ...b.timeEntries.map((t) =>
                                new Date(t.date).getTime()
                            )
                        )
                        if (minDateA !== minDateB) {
                            return minDateA - minDateB
                        }
                    } else if (a.timeEntries.length > 0) {
                        return -1 // a has timeEntries but b doesn't, so a comes first
                    } else if (b.timeEntries.length > 0) {
                        return 1 // b has timeEntries but a doesn't, so b comes first
                    }

                    // Fallback to description comparison
                    return (a.description || '').localeCompare(
                        b.description || ''
                    )
                }
            }),
        ])
    }, [
        [
            ...(invoice.lineItemsByPhaseId[ph.id] || []),
            ...(ph.isRootPhase
                ? [
                      ...(invoice.lineItemsByPhaseId[null] || []),
                      ...(invoice.lineItemsByPhaseId[undefined] || []),
                      ...(invoice.lineItemsByPhaseId[-1] || []),
                  ]
                : []),
        ].length,
        invoice.id,
    ])
    return (
        <div>
            <div>
                <div>
                    <h4
                        style={{
                            fontSize: '1.6em',
                            margin: hasProgressLine
                                ? '1em 0 0 0.5em'
                                : '1em 0 0.5em 0.5em',
                        }}
                    >
                        {ph.title || ph.name}
                        {hasProgressLine ? (
                            <span>
                                {' - '}
                                {FormatPercent(prevPercent)}{' '}
                                <i
                                    style={{
                                        margin: 0,
                                        fontSize: '0.6em',
                                        position: 'relative',
                                        top: '-0.25em',
                                    }}
                                    className="fa fa-arrow-right"
                                />
                                {FormatPercent(currentPercent)}
                            </span>
                        ) : ph ? (
                            <span
                                style={{
                                    marginLeft: '1em',
                                    fontSize: '0.6em',
                                    color: '#888',
                                    fontStyle: 'italic',
                                }}
                            >
                                (Phase has no fee set)
                            </span>
                        ) : null}
                    </h4>
                    {hasProgressLine ? (
                        <PhaseProgressLine
                            fromPercentage={prevPercent}
                            toPercentage={currentPercent}
                        />
                    ) : null}
                </div>
            </div>
            <Table
                columns={lineItemColumns}
                rows={lineItems.filter((r) => !r.deletedAt)}
                fullWidth={true}
            />
            {canEditProjectInvoices(SessionStore.user, ph.project) ? (
                <div className="flex items-center justify-between text-right">
                    <div className="inline-block p-[1em]">
                        <DropdownButton
                            className="add-line-item-button"
                            buttonText={
                                <span>
                                    <i
                                        className="fa fa-plus"
                                        style={{ margin: 0 }}
                                    />
                                    &nbsp;Add Line Item
                                </span>
                            }
                            options={[
                                ...(!ph?.isRootPhase
                                    ? [
                                          {
                                              label: 'Phase Progress',
                                              value: 'progress',
                                              ...(ph.fee == null || ph.fee === 0
                                                  ? {
                                                        disabled: true,
                                                        title: 'Set a fee for this phase to create progress line items',
                                                    }
                                                  : {}),
                                          },
                                          {
                                              label: 'Less Phase Previously Billed',
                                              value: 'previouslyBilled',
                                          },
                                      ]
                                    : [
                                          {
                                              label: 'Project Progress',
                                              value: 'projectProgress',
                                          },
                                          {
                                              label: 'Less Project Previously Billed',
                                              value: 'projectPreviouslyBilled',
                                          },
                                      ]),
                                ...[
                                    {
                                        label: 'Timesheets',
                                        value: 'timesheets',
                                    },
                                    {
                                        label: 'Fixed Amount',
                                        value: 'fixed',
                                    },
                                    {
                                        label: 'Expense',
                                        value: 'expense',
                                    },
                                    {
                                        label: 'Note',
                                        value: 'note',
                                    },
                                ],
                            ]}
                            onItemClick={(lineItemType) => {
                                switch (lineItemType) {
                                    case 'progress':
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    ph?.id ||
                                                    invoice.project.rootPhaseId,
                                                billingType: 'agreedFee',
                                                lineItemType,
                                                unitCost: ph.fee || 0,
                                                isTaxed: true,
                                                description:
                                                    '[phase] ([phaseprogress] completion)',
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )
                                    case 'projectProgress':
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    invoice.project.rootPhaseId,
                                                billingType: 'agreedFee',
                                                lineItemType,
                                                unitCost: invoice.project.fee,
                                                isTaxed: true,
                                                description:
                                                    '[phase] ([phaseprogress] completion)',
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )
                                    case 'previouslyBilled':
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    ph?.id ||
                                                    invoice.project.rootPhaseId,
                                                billingType: 'agreedFee',
                                                lineItemType,
                                                unitQuantity: 1,
                                                unitCost:
                                                    invoice.cachedData.phases[
                                                        ph.id
                                                    ]?.previousBilled * -1 || 0,
                                                isTaxed: true,
                                                description:
                                                    'Less previously billed',
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )
                                    case 'projectPreviouslyBilled':
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    ph?.id ||
                                                    invoice.project.rootPhaseId,
                                                billingType: 'agreedFee',
                                                lineItemType,
                                                unitCost:
                                                    _.sum(
                                                        invoice.project.phases.map(
                                                            (ph) =>
                                                                invoice
                                                                    .cachedData
                                                                    .phases[
                                                                    ph.id
                                                                ]
                                                                    ?.previousBilled ||
                                                                0
                                                        )
                                                    ) * -1,
                                                isTaxed: true,
                                                description:
                                                    'Less previously billed',
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )
                                    case 'timesheets':
                                        return LayoutStore.openModal(
                                            <AddTimesheetModal
                                                modalId="addTimesheetLineItem"
                                                invoice={invoice}
                                                project={invoice.project}
                                                phase={ph}
                                                timeEntries={
                                                    FetchStore.getResponse(
                                                        'te' + invoice.id
                                                    ).timeEntries.timeEntries
                                                }
                                            />
                                        )
                                    case 'expense':
                                        return ph?.expenses?.length
                                            ? LayoutStore.openModal(
                                                  <AddExpenseModal
                                                      modalId="addExpenseLineItem"
                                                      invoice={invoice}
                                                      project={invoice.project}
                                                      phase={ph}
                                                  />
                                              )
                                            : InvoiceLineItemCollection.add(
                                                  {
                                                      invoiceId: invoice.id,
                                                      projectId:
                                                          invoice.project.id,
                                                      phaseId:
                                                          ph?.id ||
                                                          invoice.project
                                                              .rootPhaseId,
                                                      billingType:
                                                          'reimbursement',
                                                      lineItemType,
                                                      isTaxed: true,
                                                  },
                                                  {
                                                      trackUpdates: true,
                                                  }
                                              )
                                    case 'note':
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    ph?.id ||
                                                    invoice.project.rootPhaseId,
                                                billingType: 'note',
                                                lineItemType: 'note',
                                                isTaxed: true,
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )

                                    default:
                                        return InvoiceLineItemCollection.add(
                                            {
                                                invoiceId: invoice.id,
                                                projectId: invoice.project.id,
                                                phaseId:
                                                    ph?.id ||
                                                    invoice.project.rootPhaseId,
                                                billingType: 'agreedFee',
                                                lineItemType,
                                                isTaxed: true,
                                            },
                                            {
                                                trackUpdates: true,
                                            }
                                        )
                                }
                            }}
                        />

                        {ph?.isRootPhase ? (
                            <button
                                className="btn btn-default"
                                onClick={() =>
                                    LayoutStore.openModal(
                                        <AddExpenseModal
                                            modalId="addExpenseLineItem"
                                            invoice={invoice}
                                            project={invoice.project}
                                        />
                                    )
                                }
                                style={{ marginLeft: '2em' }}
                            >
                                Sync expenses
                            </button>
                        ) : null}
                    </div>
                    <div>
                        {invoice.lineItemsByPhaseId[ph.id]?.length > 0 ? (
                            <div
                                style={{
                                    fontSize: '1.3em',
                                    display: 'inline-block',
                                    padding: '0.5em 4em',
                                    margin: '1em 0.5em 0.5em 0.5em',
                                    borderTop: 'solid 2px #777',
                                }}
                            >
                                {FormatCurrency(
                                    _.sum(
                                        invoice.lineItemsByPhaseId[ph.id].map(
                                            (li) => li.amount
                                        )
                                    )
                                )}
                                {' + '}
                                {FormatCurrency(
                                    _.sum(
                                        invoice.lineItemsByPhaseId[ph.id].map(
                                            (li) => li.tax
                                        )
                                    )
                                )}{' '}
                                tax
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    )
})

const PhaseProgressLine = ({ fromPercentage, toPercentage }) => {
    return (
        <div
            style={{
                width: '100%',
                height: '0.5em',
                backgroundColor: '#aaa',
                position: 'relative',
                margin: '0.5em 0 1em',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: Math.min(fromPercentage * 100, 100) + '%',
                    height: '100%',
                    backgroundColor:
                        fromPercentage * 100 <= 100 ? 'gold' : '#f05a28',
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: fromPercentage * 100 + '%',
                    width:
                        Math.min(
                            toPercentage * 100 - fromPercentage * 100,
                            100 - fromPercentage * 100
                        ) + '%',
                    height: '100%',
                    background:
                        toPercentage <= 100
                            ? 'repeating-linear-gradient(135deg, gold, gold 3px, #aaa 3px, #aaa 6px)'
                            : 'repeating-linear-gradient(135deg, gold, gold 3px, #f05a28 3px, #f05a28 6px)',
                    borderRight:
                        toPercentage <= 100
                            ? 'solid 2px gold'
                            : 'solid 2px #f05a28',
                }}
            />
        </div>
    )
}

const DropdownButton = ({
    className,
    buttonText,
    options,
    onItemClick,
    ...props
}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <Dropdown3
            isExpanded={isExpanded}
            onToggle={(expanded) => setIsExpanded(expanded)}
            toggleElement={
                <button className="btn btn-default invoice__phase__dropdown-toggle">
                    {buttonText}
                </button>
            }
            contentStyle={{ width: 200 }}
            {...props}
        >
            {options.map(function ({ label, value, disabled, title }, i) {
                return (
                    <Dropdown3ListItem
                        className="dropdown-button__menu-item"
                        key={i}
                        identifier={value}
                        disabled={disabled}
                        title={title}
                        onClick={() => onItemClick(value)}
                    >
                        {label}
                    </Dropdown3ListItem>
                )
            })}
        </Dropdown3>
    )
}

const LineItemDescription = observer(
    ({ value, onChange, stores, editable, lineItem }) => {
        const li = stores.row.rowObject
        const [focused, setFocused] = useState(false)
        const templates =
            InvoiceDescriptionTemplateCollection.descriptionTemplates.filter(
                (dt) => !dt.deletedAt
            )
        const [selectedTemplate, setSelectedTemplate] = useState(null)
        if (!editable)
            return (
                <div style={{ whiteSpace: 'pre' }}>
                    {li.formattedDescription}
                </div>
            )
        return (
            <div
                className="coincraft-table-cell__textarea"
                style={{
                    position: 'relative',
                    width: '100% ',
                }}
            >
                <div
                    className="flex"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '0.75em',
                        fontWeight: 'bold',
                        height: '2em',
                    }}
                >
                    <Selector
                        selectedOption={selectedTemplate}
                        onChange={(v) => {
                            setSelectedTemplate(v)
                            lineItem.update({ description: v.description })
                        }}
                        options={templates}
                        optionLabel={(t) => t?.name}
                        placeholder={'Template...'}
                        variant="secondary"
                    />
                    <button
                        className="btn btn-default"
                        style={{ padding: '0em 0.4em' }}
                        onClick={(e) => {
                            const newTemplate =
                                InvoiceDescriptionTemplateCollection.add(
                                    {
                                        name: 'New Template',
                                        description: lineItem.description,
                                    },
                                    { trackUpdates: true }
                                )
                            LayoutStore.openModal(
                                <InvoiceDescriptionTemplateModal
                                    modalId={'invoice-templates'}
                                    defaultSelectedTemplate={newTemplate}
                                    defaultState={'edit'}
                                />
                            )
                        }}
                    >
                        <i className="fa fa-floppy-o" style={{ margin: 0 }}></i>
                    </button>
                    <button
                        className="btn btn-default"
                        style={{ padding: '0em 0.4em' }}
                        onClick={() =>
                            LayoutStore.openModal(
                                <BilledTimeModal
                                    modalId="addTimesheetLineItem"
                                    lineItem={lineItem}
                                />
                            )
                        }
                    >
                        <i className="fa fa-clock-o" style={{ margin: 0 }}></i>
                    </button>
                </div>
                <textarea
                    rows={
                        (focused
                            ? li.description || ''
                            : li.formattedDescription || ''
                        ).split('\n').length
                    }
                    value={focused ? li.description : li.formattedDescription}
                    disabled={!editable}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    style={{ padding: '1.5em 1em 1em 1em' }}
                />
            </div>
        )
    }
)
