import cuid from 'cuid'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../Modal'
import DataStore from '../../State/DataStore'
import TextValue from '../Widgets/TextValue'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'

export default observer(({ report, modalId }) => {
    const [newName, setNewName] = useState('New Report')
    const navigate = useNavigate()
    return (
        <Modal
            modalId={modalId}
            heading="Save As..."
            onSave={() => {
                const newReport = report.clone(
                    { name: newName, systemReport: false },
                    { trackUpdates: true }
                )
                DataStore.saveModel(newReport)
                navigate({
                    search: (prev) => ({
                        ...prev,
                        report: newReport.id,
                    }),
                })
            }}
            saveLabel="Save New Report"
        >
            <div>Please enter new name for this Report:</div>
            <TextValue
                value={newName}
                onChange={(v) => setNewName(v)}
                style={{
                    marginTop: '4px',
                }}
            />
        </Modal>
    )
})
