import { createRootRoute, Outlet, redirect } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import ErrorComponent from '../Components/ErrorComponent'
const isDev = process.env.REACT_APP_ENV === 'development'

export const Route = createRootRoute({
    beforeLoad: async ({ location }) => {
        if (location.href.includes('dashboard/')) {
            throw redirect({ to: location.href.replace('dashboard/', '') })
        }
    },
    component: () => (
        <>
            <Outlet />
            {/* {isDev ? <TanStackRouterDevtools /> : null}  */}
        </>
    ),
    errorComponent: ({ error, reset }) => {
        return <ErrorComponent error={error} reset={reset} />
    },
})
